import api from '@/backend-api';
import Vue from 'vue';

export default {
  methods: {
    downloadAnhang(name, anzeigename) {
      const userAgent = navigator.userAgent || window.opera;

      if (/iPad|iPhone|iPod|CriOS|Macintosh/.test(userAgent) && !window.MSStream && 'ontouchstart' in window) {
        this.downloadAnhangIos(name);
      } else {
        this.downloadAnhangDefault(name, anzeigename);
      }
    },
    downloadAnhangIos(name) {
      const newWindow = window.open();

      api.getDokument(name, Vue.$keycloak.token).then((dokument) => {
        const binaryString = window.atob(dokument.data);
        const bytes = new Uint8Array(Array.from(binaryString, (c) => c.charCodeAt(0)));
        const blobType = this.getMimeType(name);
        const blob = new Blob([bytes], { type: blobType });

        newWindow.location = URL.createObjectURL(blob);

        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          // eslint-disable-next-line
          alert('Das Dokument konnte nicht geöffnet werden. Bitte erlauben Sie Pop-ups in Ihrem Browser.');
        }
      }).catch((error) => {
        console.error('Fehler beim Herunterladen des Dokuments:', error);
      });
    },
    async downloadAnhangDefault(name, anzeigename) {
      const dokument = await api.getDokument(
        name,
        Vue.$keycloak.token,
      );
      const binaryString = window.atob(dokument.data);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const fileURL = window.URL.createObjectURL(new Blob([bytes]));
      const fileLink = document.createElement('a');
      fileLink.href = encodeURI(fileURL);

      const anzeigenameContainsExtension = /[^.]+\.[^.]/.test(anzeigename);
      if (anzeigenameContainsExtension) {
        fileLink.setAttribute('download', anzeigename);
      } else {
        // add original file extension
        const extension = name.split('.').pop();
        fileLink.setAttribute('download', `${anzeigename}.${extension}`);
      }

      document.body.appendChild(fileLink);
      fileLink.click();
    },
    getMimeType(fileName) {
      switch (true) {
        case fileName.endsWith('.jpg'):
        case fileName.endsWith('.jpeg'):
          return 'image/jpeg';
        case fileName.endsWith('.png'):
          return 'image/png';
        case fileName.endsWith('.gif'):
          return 'image/gif';
        default:
          return 'application/pdf';
      }
    },
    downloadVertreterServicekarte(vertreternummer) {
      api.getVertreterServicekarte(vertreternummer)
        .then((response) => {
          const file = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.setAttribute('download', 'servicekarte.pkpass');
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          console.log(e);
          this.$bvToast.show('servicekarte-error');
        });
    },
    downloadKfzServicekarte(vsnr, laufendenr, produktkey) {
      api.getKfzServicekarte(vsnr, laufendenr, produktkey)
        .then((response) => {
          const file = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.setAttribute('download', 'servicekarte.pkpass');
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => console.log(e));
    },
  },
};
