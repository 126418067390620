<template>
  <div id="postfach-view">
    <app-header textLine1="Mein Postfach"></app-header>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="ausloeschen"
      :titel="titelAbfrage"
      :text="textAbfrage"
      @zustimmung="softLoeschen"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <div class="container main-content position-relative">
      <div class="row d-flex justify-content-start">
        <suche :show-filter="true" :filter-items="vertragsarten"
               @selected="sucheAnwenden" @filterMenuToggled="calcVertragfilterMenuShift"
               class="col-md-8 col-12"></suche>
        <div class="col-md-4 col-12" v-if="buttonDeaktivieren">
          <input type="button" id="nachricht-btn" class="button-ivv white"
                 :disabled="zugriffsStatus.orgaStatus !== 'eigeneOrga'"
                 :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                 value="+ Neue Nachricht" @click="zurNachricht">
        </div>
        <div class="col-md-4 col-12" v-else-if="hatNachrichten">
          <input type="button" id="download-btn" class="button-ivv white"
                 :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                 value="Download alle Dateien" @click="downloadAll">
        </div>
      </div>
      <div class="ansicht-filter">
        <input type="radio" id="alle" value="alle" v-model="ansicht"
               @change="reloadNeuigkeiten"/>
        <label for="alle"
               :class="{'vgh-blau-color active': mandant === 1 && ansicht === 'alle',
                        'oesa-gruen-color active': mandant === 3 && ansicht === 'alle',
                        'oevo-blau-color active': mandant === 5 && ansicht === 'alle'}">
          Alle</label>
        <input type="radio" id="ungelesen" value="ungelesen" v-model="ansicht"
               @change="reloadNeuigkeiten"/>
        <label for="ungelesen"
               :class="{'vgh-blau-color active': mandant === 1 && ansicht === 'ungelesen',
                        'oesa-gruen-color active': mandant === 3 && ansicht === 'ungelesen',
                        'oevo-blau-color active': mandant === 5 && ansicht === 'ungelesen'}">
          Ungelesen</label>
        <input type="radio" id="archiv" value="archiv" v-model="ansicht"
               @change="reloadNeuigkeiten"/>
        <label for="archiv"
               :class="{'vgh-blau-color active': mandant === 1 && ansicht === 'archiv',
                        'oesa-gruen-color active': mandant === 3 && ansicht === 'archiv',
                        'oevo-blau-color active': mandant === 5 && ansicht === 'archiv'}">
          Archiv</label>
      </div>
      <div class="row">
        <div class="col-12 benachrichtigung">
          <slot name="benachrichtigung"></slot>
        </div>
        <div id="nachrichten-uebersicht" class="col-12">
          <div v-if="!this.isLoadingMessages && !this.neuigkeiten.length">
            <div v-if="this.postfachStatus.gesamt === 0">
              <label class="col-12 nichtsGefundenInfo">
                In Ihrem Postfach sind keine Nachrichten vorhanden.
              </label>
            </div>
            <div v-else-if="!!this.suchtext || this.vertragfilter.length > 0">
              <label class="col-12 nichtsGefundenInfo">
                Ihre Suche führte zu keinem Ergebnis.
              </label>
            </div>
            <div v-else-if="this.ansicht === 'archiv'">
              <label class="col-12 nichtsGefundenInfo">
                Das Archiv enthält nur Nachrichten, die älter als ein Jahr sind.
              </label>
            </div>
          </div>
          <swipe-list
            ref="list"
            :items="neuigkeiten"
            item-key="id"
            :disabled="platform === 'desktop'"
            @rightRevealed="rightRevealed($event)">
            <template v-slot="{ item, index }">
              <nachrichtenbalke
                :key="index"
                :neuigkeit="item"
                @konversationAusloeschenBestaetigung =
                  "konversationAusloeschenBestaetigung(item.konversationsId)"/>
            </template>
            <template v-slot:right="{ item }">
              <div class="swipeout-action" title="Löschen"
                   @click="konversationAusloeschenBestaetigung(item.konversationsId)">
                <span>Löschen</span>
              </div>
            </template>
          </swipe-list>
          <div ref="swipeListEnd"></div>
          <div :style="{height: vertragfilterMenuShift + 'px'}" >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="module">
// @ is an alias to /src
import AppHeader from '@/components/Header.vue';
import bestaetigungUtil from '@/bestaetigungUtil';
import api from '@/backend-api';
import Nachrichtenbalke from '@/components/Nachrichtenbalke.vue';
import mandant from '@/mandant';
import Vue from 'vue';
import { mapState } from 'vuex';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import { SwipeList } from 'vue-swipe-actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import Suche from '@/components/Suche.vue';
import platform from '@/platform';

export default {
  name: 'Postfach',
  mixins: [bestaetigungUtil, mandant, platform],
  data() {
    return {
      neuigkeiten: [],
      vertragsarten: [...new Set(
        this.$store.state.vertraege.map((vertrag) => vertrag.produktname),
      )],
      ansicht: 'alle', // alle, ungelesen, archiv
      isLoadingMessages: true,
      page: 0,
      pageSize: 5,
      globalesZurueck: true,
      suchtext: '',
      vertragfilter: [],
      showAbfrage: false,
      titelAbfrage: 'Löschung der Konversation',
      textAbfrage: 'Wollen Sie diese Konversation und die gegebenenfalls '
        + 'dazu gehörigen Anhänge wirklich unwiderruflich löschen?',
      konversationIdZumLoeschen: '',
      lastSwippedIndex: '',
      firstClientY: '',
      vertragfilterMenuShift: 0,
    };
  },
  props: ['bestaetigung'],
  components: {
    Suche,
    AppHeader,
    Nachrichtenbalke,
    Sicherheitsabfrage,
    SwipeList,
  },
  computed: {
    ...mapState(['zugriffsStatus', 'postfachStatus']),
    buttonDeaktivieren() {
      return !(this.zugriffsStatus.benutzerkontoStatus === 'geloescht' || this.zugriffsStatus.benutzerkontoStatus === 'keinVertrag');
    },
    hatNachrichten() {
      return this.postfachStatus.gesamt > 0;
    },
  },
  methods: {
    calcVertragfilterMenuShift(filterMenuRef) {
      if (!filterMenuRef) {
        this.vertragfilterMenuShift = 0;
        return;
      }

      const menuHeight = filterMenuRef.clientHeight;
      const screenHeight = this.windowHeight;
      const navBarHeight = 70;
      const menuStartPos = filterMenuRef.getBoundingClientRect().top;
      const footerHeight = this.$root.$el.getElementsByTagName('footer').item(0).clientHeight;

      let shiftBy = 0;

      if (menuHeight + menuStartPos > screenHeight - navBarHeight - footerHeight) {
        shiftBy = (menuHeight + menuStartPos) - (screenHeight - navBarHeight - footerHeight);
      }

      this.vertragfilterMenuShift = shiftBy;
    },
    rightRevealed({ index }) {
      if (this.lastSwippedIndex !== index) {
        this.$refs.list.closeActions(this.lastSwippedIndex);
        this.lastSwippedIndex = index;
      }
    },
    konversationAusloeschenBestaetigung(Konversationid) {
      this.showAbfrage = true;
      this.konversationIdZumLoeschen = Konversationid;
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    async softLoeschen() {
      try {
        await api.setNachrichtenGeloeschtByKonversationsId(
          this.konversationIdZumLoeschen,
          Vue.$keycloak.token,
        );
      } catch (e) {
        console.log(e);
      }
      this.konversationIdZumLoeschen = '';
      this.showAbfrage = false;
      await this.$store.dispatch('ladePostfachstatus');
      this.reloadNeuigkeiten();
    },
    async downloadAll() {
      const dokumente = await api.getAlleDokumenteAlsZip(
        Vue.$keycloak.token,
      );
      const binaryString = window.atob(dokumente.data);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const fileURL = window.URL.createObjectURL(new Blob([bytes]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'Dateien.zip');
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async fillPage() {
      this.$root.$el.addEventListener('scroll', this.infiniteScroll);
      this.$root.$el.addEventListener('pointerdown', this.pointerHandler);
      this.isLoadingMessages = true;
      await this.loadNextPage();
    },
    zurNachricht() {
      this.$router.push({ name: 'Nachricht' });
    },
    sucheAnwenden(suchtext, vertragFilter) {
      this.suchtext = suchtext;
      this.vertragfilter = vertragFilter;
      this.reloadNeuigkeiten();
    },
    reloadNeuigkeiten() {
      this.page = 0;
      this.neuigkeiten = [];
      this.fillPage();
    },
    async loadNextPage() {
      let status;
      switch (this.ansicht) {
        case 'ungelesen':
          status = 'ungelesen';
          break;
        case 'archiv':
          status = 'gelesen';
          break;
        default:
          status = 'alle';
          break;
      }
      const istSuche = !!this.suchtext || this.vertragfilter.length > 0;
      const vorEinemJahr = new Date(new Date().setDate(new Date().getDate() - 366));
      const start = !istSuche && this.ansicht === 'alle' ? vorEinemJahr.toISOString().substring(0, 10) : null;
      const ende = !istSuche && this.ansicht === 'archiv' ? vorEinemJahr.toISOString().substring(0, 10) : null;

      let lastPageLoaded = false;
      await api.getNachrichtPageGrouped(
        this.page,
        this.pageSize,
        this.suchtext,
        this.vertragfilter,
        status,
        start,
        ende,
        Vue.$keycloak.token,
      ).then((response) => response.data)
        .then((neuigkeiten) => {
          this.neuigkeiten = this.neuigkeiten.concat(neuigkeiten);
          this.isLoadingMessages = false;
          this.page += 1;
          if (neuigkeiten.length < this.pageSize) {
            lastPageLoaded = true;
            this.$root.$el.removeEventListener('scroll', this.infiniteScroll);
            this.$root.$el.removeEventListener('touchmove', this.infiniteScroll);
          }
        }).catch((error) => {
          this.$store.commit('SET_FEHLER', true);
          console.log(`NachrichtenPage: ${error}`);
        });
      // while there are more pages & the view is not filled completely, we load more pages
      if (!lastPageLoaded && this.isLastNachrichtVisible()) {
        await this.loadNextPage();
      }
    },
    async infiniteScroll(event) {
      if (this.isLoadingMessages) {
        return;
      }
      if (event.type === 'touchmove') {
        await this.handleTouchMoveEvent(event);
      } else if (event.type === 'scroll') {
        await this.handleScrollEvent(event);
      }
    },
    async handleTouchMoveEvent(event) {
      const minValue = 30;
      const maxValue = -30;
      const clientY = event.touches[0].clientY - this.firstClientY;
      if ((clientY > minValue || clientY < maxValue)
        && (clientY < 0) && event.cancelable) {
        this.isLoadingMessages = true;
        await this.loadNextPage();
        event.preventDefault();
      }
    },
    isLastNachrichtVisible() {
      const rect = this.$refs.swipeListEnd.getBoundingClientRect();

      return rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },
    async handleScrollEvent() {
      if (this.isLastNachrichtVisible()) {
        this.isLoadingMessages = true;
        await this.loadNextPage();
      }
    },
    startMoveEvent(event) {
      this.firstClientY = event.touches[0].clientY;
    },
    pointerHandler(event) {
      if (event.pointerType === 'touch') {
        this.$root.$el.removeEventListener('scroll', this.infiniteScroll);
        this.$root.$el.addEventListener('touchmove', this.infiniteScroll);
        this.$root.$el.addEventListener('touchstart', this.startMoveEvent);
      }
      this.$root.$el.removeEventListener('pointerdown', this.pointerHandler);
    },
  },
  beforeMount() {
    this.$root.$refs.app.setZurueckFeld(this.globalesZurueck);
  },
  beforeDestroy() {
    this.$root.$el.removeEventListener('scroll', this.infiniteScroll);
    this.$root.$el.removeEventListener('touchmove', this.infiniteScroll);
  },
  async mounted() {
    try {
      await this.fillPage();
    } catch (e) {
      console.error(e.message);
    } finally {
      this.$emit('view-geladen');
    }
  },
};
</script>

<style scoped>
#nachricht-btn {
  position: absolute;
  right: 15px;
  max-width: 155px;
}

#download-btn {
  position: absolute;
  right: 15px;
  max-width: 195px;
}

.main-content > div:first-child, .ansicht-filter {
  margin-bottom: 20px;
}

.nichtsGefundenInfo {
  background-color: #999999;
  text-align: center;
  color: white;
  margin-top: 2em;
  margin-bottom: 1em;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}

input[type="search"] {
  border: 2px solid #999;
  font-size: 1.2em;
  padding-left: 0.5em;
  padding-right: 1.6em;
  margin-top: 5px;
  height: 30px
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.swipeout-action {
  display: flex;
  cursor: pointer;
  background-color: var(--rot);
  color: white;
  width: 75px;
  margin-bottom: 2px;
  text-align: center;
  align-items: center;
}

.swipeout-action > span {
  width: 100%;
}

.ansicht-filter input, .ansicht-filter label:before {
  display: none !important;
}

.ansicht-filter label {
  margin-bottom: 0;
  margin-right: 10px;
}

.ansicht-filter label.active {
  font-weight: bold;
}

/* Phone View */
@media (max-width: 576px) {
  #nachricht-btn {
    left: 15px;
    margin-top: -10px;
  }

  #download-btn {
    left: 15px;
  }

  .nichtsGefundenInfo {
    margin-top: -10px;
  }

  .ansicht-filter {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

/* Tablet View */
@media (min-width: 576px) and (max-width: 992px) {
  .nichtsGefundenInfo {
    margin-top: -10px;
  }
}

</style>
