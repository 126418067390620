<template>
  <div>
    <div>
      <app-header textLine1="Schadenübersicht"></app-header>
      <div id="info-bereich" class="container main-content position-relative">
        <slot name="benachrichtigung"></slot>
        <div class="row d-flex justify-content-start">
          <suche class="suche col-md-8" @selected="suche" :show-filter="true"
                 :filter-items="sparten" mobile-margin="-3" tablet-margin="-4" input-width="350px">
          </suche>
          <hinweis class="mt-1" right="13px" size="30px" font-size="20px">
            Aus technischen Gründen kann es sein,
            dass nicht alle Ihre Schäden hier angezeigt werden.
            Sollten Sie Informationen vermissen oder Fragen haben,
            steht Ihnen Ihre Vertretung gerne zur Verfügung.
          </hinweis>
        </div>
        <div class="p-4 p-xl-0"></div>
        <div v-if="schaden.geladen && schaeden.length === 0">
          <label v-if="schaden.fehler" class="col-12 nichtsGefundenInfo">
            Die Schadenübersicht kann aktuell leider nicht geladen werden.
          </label>
          <label v-else-if="suchtext && suchtext.length > 0" class="col-12 nichtsGefundenInfo">
            Ihre Suche führte zu keinem Ergebnis.
          </label>
          <label v-else-if="!suchtext || suchtext.length === 0" class="col-12 nichtsGefundenInfo">
            In Ihrer Schadenübersicht sind keine Schäden vorhanden.
          </label>
        </div>
        <div :id="'schaden-' + index" v-for="(schaden, index) in schaeden"
             :key="schaden.nummer"
             class="schaden-meldung">
          <schadenmeldung :schaden="schaden" :index="index"></schadenmeldung>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import AppHeader from '@/components/Header.vue';
import Suche from '@/components/Suche.vue';
import mandant from '@/mandant';
import Schadenmeldung from '@/schaden/Schadenmeldung.vue';
import { mapState } from 'vuex';
import Hinweis from '@/components/Hinweis.vue';

export default {
  name: 'SchadenUebersicht',
  mixins: [mandant],
  props: {},
  data() {
    return {
      showHinweis: false,
      erfolg: false,
      globalesZurueck: true,
      zurueckAbfrage: false,
      buttonText: 'Schaden melden',
      suchtext: '',
      spartenFilter: [],
    };
  },
  components: {
    Hinweis,
    Schadenmeldung,
    Suche,
    AppHeader,
  },
  computed: {
    ...mapState(['schaden']),
    schaeden() {
      const { schaden } = this.$store.state;
      let result = !schaden.meldungen ? [] : schaden.meldungen;
      result = result.filter(
        (s) => !this.suchtext
          || s.vsnr.toLowerCase().includes(this.suchtext.toLowerCase())
          || s.nummer.toLowerCase().includes(this.suchtext.toLowerCase())
          || s.sparte.toLowerCase().includes(this.suchtext.toLowerCase()),
      );
      result = result.filter(
        (s) => !this.spartenFilter
          || this.spartenFilter.length === 0
          || this.spartenFilter.includes(s.sparte),
      );
      return result;
    },
    sparten() {
      const { meldungen } = this.$store.state.schaden;
      if (!meldungen) {
        return [];
      }
      return [...new Set(meldungen.map((meldung) => meldung.sparte))]
        .sort((a, b) => a.localeCompare(b));
    },
  },
  methods: {
    setZurueckFeld(zurueck) {
      this.$root.$refs.app.setZurueckFeld(zurueck);
    },
    suche(suchtext, spartenFilter) {
      this.suchtext = suchtext;
      this.spartenFilter = spartenFilter;
    },
  },
  async mounted() {
    try {
      await this.suche();
    } catch (e) {
      console.error(e.message);
    } finally {
      this.$emit('view-geladen');
    }
  },
  async beforeMount() {
    await this.$store.dispatch('ladeSchadenUebersicht');
    this.setZurueckFeld(this.globalesZurueck);
  },
};
</script>

<style scoped>

.suche {
  margin-bottom: 30px;
}

.nichtsGefundenInfo {
  background-color: #999999;
  text-align: center;
  color: white;
  margin-top: 2em;
  margin-bottom: 1em;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}

.schaden-meldung:last-child .schaden-card {
  margin-bottom: 40px;
  border-bottom: 2px solid #cbcfd3;
}

@media (max-width: 576px) {
  .suche {
    margin-bottom: 10px;
  }

  .main-content {
    padding-top: 10px;
  }
}

</style>
