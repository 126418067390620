import { render, staticRenderFns } from "./Nachrichtenverlauf.vue?vue&type=template&id=8ba8ae5a&scoped=true&"
import script from "./Nachrichtenverlauf.vue?vue&type=script&lang=js&"
export * from "./Nachrichtenverlauf.vue?vue&type=script&lang=js&"
import style0 from "./Nachrichtenverlauf.vue?vue&type=style&index=0&id=8ba8ae5a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ba8ae5a",
  null
  
)

export default component.exports