<template>
  <!-- eslint-disable max-len -->
  <div>
    <app-header :textLine1="headerText"></app-header>
    <div class="container position-relative">
      <h2 class="text-links" :id="vsnr ? 'gruppen-aktive' : 'aktive'">{{ vertraegeTitel }}</h2>
      <suche v-if="vsnr" class="suche" @selected="sucheInGruppenVertraege"
             mobile-margin="-5" tablet-margin="-7" input-width="350px">
      </suche>
      <div class="row d-flex justify-content-start">
        <suche v-if="!vsnr" class="suche col-md-8" @selected="sucheVertrag"
               mobile-margin="-5" tablet-margin="-7" input-width="350px">
        </suche>
      </div>
      <div v-if="suchtext.length > 0 && displayVertraege.length === 0">
        <label class="col-12 nichtsGefundenInfo">
          Ihre Suche führte zu keinem Ergebnis.</label>
      </div>
      <div class="row">
        <div id="karten" v-for="(vertrag, index) in displayVertraege"
             :key="index"
             class="copy_16 col-xl-4" :class="{ 'col-auto': (windowWidth > 576) }">
          <karte
            :feature="vertrag.gruppiert ? 'vertrag' : 'vertragsdetails'"
            :vsnr="vertrag.vsnr"
            :laufendenr="vertrag.laufendenr"
            :produktkey="vertrag.produktkey"
            :produktname="vertrag.produktname"
            :spezifisches-merkmal="vertrag.spezifischesMerkmal"
            :jahresbeitrag="vertrag.jahresbeitrag"
            :zahlart="vertrag.zahlart"
            :button-text="buttonText">
          </karte>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import AppHeader from '@/components/Header.vue';
import Karte from '@/components/Karte.vue';
import Suche from '@/components/Suche.vue';
import mandant from '@/mandant';
import api from '@/backend-api';
import Vue from 'vue';

export default {
  name: 'Vertrag',
  mixins: [mandant],
  data() {
    return {
      headerText: 'Meine Verträge',
      buttonText: 'Details & Services',
      displayVertraege: [],
      gruppenVertraege: [],
      uebersichtVertraege: [],
      suchtext: '',
    };
  },
  components: {
    Suche,
    AppHeader,
    Karte,
  },
  props: {
    vsnr: {
      type: String,
      required: false,
    },
  },
  watch: {
    vsnr: {
      immediate: true,
      handler() {
        this.getDisplayVertraege();
        this.sortiereAktiv();
      },
    },
  },
  created() {
    this.uebersichtVertraege = [...this.displayVertraege];
  },
  async mounted() {
    try {
      await this.schadenmeldungSuche();
    } catch (e) {
      console.error(e.message);
    } finally {
      this.$emit('view-geladen');
    }
  },
  async beforeMount() {
    this.sortiereAktiv();
    this.$root.$refs.app.setZurueckFeld(true);
  },

  methods: {
    sortiereAktiv() {
      this.displayVertraege.sort((a, b) => a.produktname - b.produktname);
    },
    async getDisplayVertraege() {
      this.suchtext = '';
      if (this.vsnr) {
        this.displayVertraege = [];
        this.gruppenVertraege = await api.getGruppenVertraege(this.vsnr, Vue.$keycloak.token)
          .then((response) => response.data)
          .then((vertrag) => vertrag)
          .catch((error) => {
            console.log(error);
          });
        this.displayVertraege = this.gruppenVertraege;
      } else {
        this.displayVertraege = this.vertraegeGruppiert;
      }
    },
    sucheInGruppenVertraege(suchtext) {
      this.suchtext = suchtext;
      this.displayVertraege = this.gruppenVertraege.filter(
        (v) => v.vsnr.toLowerCase().includes(suchtext.toLowerCase())
        || v.spezifischesMerkmal?.toLowerCase().includes(suchtext.toLowerCase()),
      );
    },
    sucheVertrag(suchtext) {
      this.suchtext = suchtext;
      if (suchtext.trim() === '') {
        this.displayVertraege = [...this.uebersichtVertraege];
      } else {
        this.displayVertraege = this.uebersichtVertraege.filter(
          (v) => v.vsnr.toLowerCase().includes(suchtext.toLowerCase())
            || v.produktname.toLowerCase().includes(suchtext.toLowerCase())
            || v.spezifischesMerkmal?.toLowerCase().includes(suchtext.toLowerCase()),
        );
      }
    },

  },

  computed: {
    ...mapState(['vertraegeGruppiert']),
    vertraegeTitel() {
      if (!this.vsnr) {
        return `Übersicht zu meinen Verträgen (${this.displayVertraege.length})`;
      }
      switch (this.vsnr.substring(2, 4)) {
        case '24':
          return 'KFZ Flottenverträge';
        case '68':
          return `${{ 1: 'VGH ', 3: 'ÖSA ', 5: '' }[this.mandant]}GewerbeSchutz`;
        default:
          return 'Meine Verträge';
      }
    },
  },
};
</script>

<style scoped>
.text-links {
  text-align: left;
}

#aktive {
  margin-bottom: 30px;
}

#gruppen-aktive {
  margin-bottom: 20px;
}

.suche {
  margin-bottom: 30px;
}

.col-xl-4 {
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}

.container {
  margin-top: 60px;
}

.nichtsGefundenInfo {
  background-color: #999999;
  text-align: center;
  color: white;
  margin-top: 2em;
  margin-bottom: 1em;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}

@media (max-width: 576px) {
  .container {
    margin-top: 30px;
  }

  #aktive {
    margin-bottom: 20px;
  }

  .col-xl-4 {
    padding-bottom: 20px;
  }

  .suche {
    margin-bottom: 10px;
  }
}

</style>
