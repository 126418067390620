var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(!_vm.loading)?_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-xl-8 col-lg-8"},[_c('div',{attrs:{"id":"produkt"}},[_c('h1',{staticClass:"ueberschrift"},[_vm._v(_vm._s(_vm.vertrag.produktname))]),(!_vm.isKFZ(_vm.vsnr))?_c('div',{staticClass:"copy_16 text"},[_vm._v(_vm._s(_vm.vertrag.spezifischesMerkmal))]):_vm._e()]),_c('div',{staticClass:"row row-cols-xl-2"},[_c('div',{class:{'col-xl-6 col-lg-6': _vm.showDokumente,
                          'col-xl-12 col-lg-12': !_vm.showDokumente},attrs:{"id":"vertragsdaten"}},[_c('h2',{staticClass:"ueberschrift"},[_vm._v("Vertragsdaten")]),(_vm.vertrag.vsnr)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Vertragsnummer")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(_vm._s(_vm.vertrag.vsnr))]},proxy:true}],null,false,619577979)}):_vm._e(),(_vm.vertrag.versicherungsnehmer)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Versicherungsnehmer")]},proxy:true},{key:"inhalt",fn:function(){return [(_vm.vertrag.versicherungsnehmer.titel)?_c('span',[_vm._v(" "+_vm._s(_vm.vertrag.versicherungsnehmer.titel + ' ')+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.vertrag.versicherungsnehmer.vorname + ' ')+" "),(_vm.vertrag.versicherungsnehmer.namensvorsatz)?_c('span',[_vm._v(" "+_vm._s(_vm.vertrag.versicherungsnehmer.namensvorsatz + ' ')+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.vertrag.versicherungsnehmer.name)+" ")]},proxy:true}],null,false,927296395)}):_vm._e(),(_vm.vertrag.vertragsbeginn)?_c('vertragsdetail',{attrs:{"id":"laufzeitbeginn-hinweis"},scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Vertragsbeginn")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(_vm._s(_vm.vertrag.vertragsbeginn))]},proxy:true}],null,false,3530981116)}):_vm._e(),(_vm.vertrag.vertragsende)?_c('vertragsdetail',{attrs:{"id":"laufzeitende-hinweis"},scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Laufzeitende "),_c('div',{staticClass:"hilfe-icon",class:{'vgh-blau-bg': _vm.mandant === 1,
                      'oesa-gruen-bg': _vm.mandant === 3,
                      'oevo-blau-bg': _vm.mandant === 5},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleHinweis.apply(null, arguments)}}},[_vm._v("? "),(_vm.showHinweis)?_c('div',{staticClass:"hilfe-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"close-btn"},on:{"click":_vm.schliesseHinweis}},[_c('em',{staticClass:"fa-solid fa-xmark"})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"hinweis-text-span"},[_vm._v(" Ohne fristgerechte Kündigung kann sich die Laufzeit automatisch verlängern. ")])])])]):_vm._e()])]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(_vm._s(_vm.vertrag.vertragsende))]},proxy:true}],null,false,3392104741)}):_vm._e(),(_vm.vertrag.bedingungswerk)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Bedingungen")]},proxy:true},{key:"inhalt",fn:function(){return [_c('a',{staticClass:"copy_16 text text-link"},[_vm._v(" "+_vm._s(_vm.vertrag.bedingungswerk)+" ")])]},proxy:true}],null,false,2241255055)}):_vm._e(),(!_vm.isSparkasseOderMakler && _vm.darfSchadenMelden)?_c('div',{attrs:{"id":"schaden-melden-btn"}},[_c('pfeil-button',{class:{'vgh-blau-color': _vm.mandant === 1,
                              'oesa-gruen-color': _vm.mandant === 3,
                              'oevo-blau-color': _vm.mandant === 5},attrs:{"button-text":"Schaden melden","path":"schaden-melden","param":_vm.vertrag.vsnr + '/'
                             + _vm.vertrag.laufendenr + '/'
                             + _vm.vertrag.produktkey,"disabled":_vm.isKrankenversicherung(_vm.vsnr)}}),(_vm.isKrankenversicherung(_vm.vsnr))?_c('div',{staticClass:"hilfe-icon",class:{'vgh-blau-bg': _vm.mandant === 1,
                    'oesa-gruen-bg': _vm.mandant === 3,
                    'oevo-blau-bg': _vm.mandant === 5},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleHinweis.apply(null, arguments)}}},[_vm._v("? "),(_vm.showHinweis)?_c('div',{staticClass:"hilfe-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"close-btn"},on:{"click":_vm.schliesseHinweis}},[_c('em',{staticClass:"fa-solid fa-xmark"})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"hinweis-text-span"},[_c('span',{staticClass:"copy_16"},[_vm._v(" Ihre Schadenmeldungen, Rechnungen, Rezepte sowie Heil- und Kostenpläne zur Krankenversicherung können Sie ganz einfach über die App "),(_vm.mandant === 5 ||
                        (_vm.platform === 'desktop' && _vm.mandant !== 5))?_c('span',[_vm._v(" "+_vm._s(_vm.setMandantAppName()))]):(_vm.mandant === 1 || _vm.mandant === 3)?_c('a',{attrs:{"href":_vm.getAppStoreKranken(),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.setMandantAppName())+" ")]):_vm._e(),_vm._v(" einreichen. ")])])])])]):_vm._e()]):_vm._e()],1):_vm._e(),(!_vm.isSparkasseOderMakler)?_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('pfeil-button',{class:{'vgh-blau-color': _vm.mandant === 1,
                              'oesa-gruen-color': _vm.mandant === 3,
                              'oevo-blau-color': _vm.mandant === 5},attrs:{"button-text":"Dokument einreichen","path":"dokument-einreichen","param":_vm.vertrag.vsnr + '/'
                             + _vm.vertrag.laufendenr + '/'
                             + _vm.vertrag.produktkey}})],1):_vm._e(),(!_vm.isSparkasseOderMakler)?_c('div',[_c('pfeil-button',{class:{'vgh-blau-color': _vm.mandant === 1,
                              'oesa-gruen-color': _vm.mandant === 3,
                              'oevo-blau-color': _vm.mandant === 5},attrs:{"button-text":"Dokumente anfordern","path":"dokumente-anfordern","param":_vm.vertrag.vsnr + '/'
                             + _vm.vertrag.laufendenr + '/'
                             + _vm.vertrag.produktkey}})],1):_vm._e()],1),(_vm.showDokumente)?_c('div',{staticClass:"col-xl-6 info-bereich",attrs:{"id":"vertragsdokumente"}},[_c('div',{staticClass:"collapsible"},[_c('h2',{staticClass:"ueberschrift"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.vertragsdokumentcollapse",modifiers:{"vertragsdokumentcollapse":true}}],staticClass:"btn btn-collapse",attrs:{"type":"button","disabled":_vm.windowWidth > 1200}},[_vm._v(" Vertragsdokumente "),_c('span',{staticClass:"icon",class:{'vgh-blau-color': _vm.mandant === 1,
                              'oesa-gruen-color': _vm.mandant === 3,
                              'oevo-blau-color': _vm.mandant === 5}},[_c('em',{staticClass:"fa-solid fa-plus"}),_c('em',{staticClass:"fa-solid fa-minus"})])])]),_c('b-collapse',{attrs:{"id":"vertragsdokumentcollapse","visible":_vm.windowWidth > 1200}},[_c('vertragsdetail',{scopedSlots:_vm._u([{key:"inhalt",fn:function(){return [(_vm.vschein)?_c('div',[_c('div',{class:{'vgh-blau-color': _vm.mandant === 1,
                                      'oesa-gruen-color': _vm.mandant === 3,
                                      'oevo-blau-color': _vm.mandant === 5},staticStyle:{"line-break":"anywhere","margin-bottom":"10px"},on:{"click":function($event){return _vm.downloadAnhang(
                               _vm.vschein.name,
                               _vm.vschein.anzeigename)}}},[_c('label',{staticClass:"zeiger"},[_c('em',{staticClass:"fa-solid fa-paperclip"}),_vm._v(" Versicherungsschein ")])])]):_vm._e(),(_vm.beitragsrechnung)?_c('div',[_c('div',{class:{'vgh-blau-color': _vm.mandant === 1,
                                        'oesa-gruen-color': _vm.mandant === 3,
                                        'oevo-blau-color': _vm.mandant === 5},staticStyle:{"line-break":"anywhere"},on:{"click":function($event){return _vm.downloadAnhang(
                                 _vm.beitragsrechnung.name,
                                 _vm.beitragsrechnung.anzeigename)}}},[_c('label',{staticClass:"zeiger"},[_c('em',{staticClass:"fa-solid fa-paperclip"}),_vm._v(" Letzte "+_vm._s(_vm.beitragsrechnung.anzeigename.includes('Beitragsrechnung') ? 'Beitragsrechnung' : 'Lastschriftbenachrichtigung')+" ")])])]):_vm._e(),(_vm.isKFZ(_vm.vsnr))?_c('div',{staticClass:"servicekarte"},[_c('div',{class:{'vgh-blau-color': _vm.mandant === 1,
                                        'oesa-gruen-color': _vm.mandant === 3,
                                        'oevo-blau-color': _vm.mandant === 5}},[_c('hinweis',{attrs:{"right":_vm.windowWidth > 576 ? '140px' : '120px',"align-left":"true"}},[_vm._v(" Mit der digitalen Servicekarte haben Sie alle relevanten Informationen zu Ihrer KFZ Versicherung immer sofort auf Ihrem Smartphone in der Wallet App zur Hand. ")]),_c('label',{staticClass:"zeiger",on:{"click":_vm.zurServicekarte}},[_vm._v(" Digitale Servicekarte KFZ ")])],1),_c('div',{staticClass:"row justify-content-xl-between btn-servicekarte-wrapper"},[_c('img',{staticClass:"btn col-auto p-0 btn-servicekarte",attrs:{"src":require("@/assets/add_to_apple_wallet_badge.svg"),"alt":"Servicekarte hinzufügen"},on:{"click":_vm.zurServicekarte}}),_c('img',{staticClass:"btn col-auto p-0 btn-servicekarte ml-3 ml-xl-0",attrs:{"src":require("@/assets/add_to_google_wallet_badge.svg"),"alt":"Servicekarte hinzufügen"},on:{"click":_vm.zurServicekarte}})])]):_vm._e()]},proxy:true}],null,false,1320475735)})],1)],1)]):_vm._e(),_c('div',{staticClass:"col-xl-6 info-bereich",attrs:{"id":"vertragsdetails"}},[_c('div',{staticClass:"collapsible"},[_c('h2',{staticClass:"ueberschrift"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.vertragsdetailscollapse",modifiers:{"vertragsdetailscollapse":true}}],staticClass:"btn btn-collapse",attrs:{"type":"button","disabled":_vm.windowWidth > 1200}},[_vm._v(" Vertragsdetails "),_c('span',{staticClass:"icon",class:{'vgh-blau-color': _vm.mandant === 1,
                              'oesa-gruen-color': _vm.mandant === 3,
                              'oevo-blau-color': _vm.mandant === 5}},[_c('em',{staticClass:"fa-solid fa-plus"}),_c('em',{staticClass:"fa-solid fa-minus"})])])]),_c('b-collapse',{attrs:{"id":"vertragsdetailscollapse","visible":_vm.windowWidth > 1200}},[(_vm.isKFZ(_vm.vsnr) && _vm.vertrag.spezifischesMerkmal !== null
                    && _vm.vertrag.spezifischesMerkmal.length > 0)?_c('div',[_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Amtliches Kennzeichen")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(" "+_vm._s(_vm.extrahiereKfzKennzeichen(_vm.vertrag.spezifischesMerkmal))+" ")]},proxy:true}],null,false,1636045542)}),_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Beschreibung")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(" "+_vm._s(_vm.extrahiereKfzBeschreibung(_vm.vertrag.spezifischesMerkmal))+" ")]},proxy:true}],null,false,3823531910)})],1):_c('div',{staticClass:"hinweis-kasten mr-xl-3"},[_c('div',{staticClass:"copy_bold_16 hinweis-kasten-ueberschrift"},[_c('span',{staticClass:"news-badge"},[_vm._v("i")]),_vm._v(" Vertragsdetails in Arbeit "),_c('div',{staticClass:"copy_16 hinweis-kasten-text"},[_vm._v(" Aktuell sind noch nicht alle Vertragsdetails verfügbar. Wir arbeiten derzeit daran, diese Funktion für diesen Vertrag zur Verfügung zu stellen. ")])])])])],1)]),(_vm.vertrag.zahlart)?_c('div',{staticClass:"info-bereich col-xl-6",attrs:{"id":"zahlungsinfo"}},[_c('div',{staticClass:"collapsible"},[_c('h2',{staticClass:"ueberschrift"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.zahlungsinformationencollapse",modifiers:{"zahlungsinformationencollapse":true}}],staticClass:"btn btn-collapse",attrs:{"type":"button","disabled":_vm.windowWidth > 1200}},[_vm._v(" Zahlungsinformationen "),_c('span',{staticClass:"icon",class:{'vgh-blau-color': _vm.mandant === 1,
                              'oesa-gruen-color': _vm.mandant === 3,
                              'oevo-blau-color': _vm.mandant === 5}},[_c('em',{staticClass:"fa-solid fa-plus"}),_c('em',{staticClass:"fa-solid fa-minus"})])])]),_c('b-collapse',{attrs:{"id":"zahlungsinformationencollapse","visible":_vm.windowWidth > 1200}},[(_vm.vertrag.jahresbeitrag)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Beitrag")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatiereGeldbetrag(_vm.vertrag.jahresbeitrag))+" / jährlich ")]},proxy:true}],null,false,775505658)}):_vm._e(),(_vm.vertrag.iban)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("IBAN")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(_vm._s(_vm.vertrag.iban))]},proxy:true}],null,false,4009865212)}):_vm._e(),(_vm.vertrag.kontoinhaber)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Kontoinhaber")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(_vm._s(_vm.vertrag.kontoinhaber))]},proxy:true}],null,false,1350801692)}):_vm._e(),(_vm.vertrag.zahlart)?_c('vertragsdetail',{scopedSlots:_vm._u([{key:"unter-ueberschrift",fn:function(){return [_vm._v("Zahlungsweise")]},proxy:true},{key:"inhalt",fn:function(){return [_vm._v(_vm._s(_vm.vertrag.zahlart))]},proxy:true}],null,false,273633241)}):_vm._e()],1)],1)]):_vm._e()])]),_c('div',{staticClass:"col-xl-4 col-lg-4"},[_c('vertreter-info',{attrs:{"ueberschrift":"Sie haben Fragen? Wir helfen Ihnen gerne weiter.","anschrift-ausblenden":_vm.isSparkasseOderMakler,"mail-adresse":_vm.vertreterMail,"nachricht":!_vm.isSparkasseOderMakler,"vsnr":_vm.vsnr,"laufendenr":_vm.laufendenr,"produktkey":_vm.produktkey,"termin":!_vm.isSparkasseOderMakler,"vertrLink":_vm.vertreterLink,"social-media":!_vm.isMakler,"vertreter":_vm.vertrag.bestandsvertreter}})],1)])],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }