<template>
  <!-- eslint-disable max-len -->
  <div id="sidebar">
    <div ref="kopf" v-b-toggle.mehr @click="behandleHochScroll">
      <em v-if="mobil" id="mehr-icon" class="fa-solid fa-ellipsis-h fa-3x">
      </em>
      <p id="kopf">Mehr</p>
    </div>
    <b-sidebar
      id="mehr"
      :class="{'b-sidebar-vgh': mandant === 1,
        'b-sidebar-oesa': mandant === 3,
        'b-sidebar-oevo': mandant === 5}"
      aria-labelledby="sidebar-no-header-title"
      no-close-on-route-change
      no-header
      backdrop
      @hidden="aktiviereScrollBar"
      @shown="deaktiviereScrollBar"
      right
      lazy>
      <div class="side-content">
        <h3 class="side-ueberschrift">
          Mehr
          <button class="side-x" v-b-toggle.mehr>
            <em class="fa-solid fa-xmark"></em>
          </button>
        </h3>
        <div>
          <button class="copy_16 side-card side-element"
                  @click="zumProfil">
            Mein Profil
          </button>
        </div>
        <div>
          <button class="copy_16 side-element side-card"
                  @click="zumVertreter"
                  :disabled="zugriffsStatus.orgaStatus !== 'eigeneOrga'"
          >
            Meine Vertretung
          </button>
        </div>
        <einstellung class="copy_16 side-element side-card"></einstellung>
        <div>
          <a class="faqLink" target="_blank" :href="faqLink">
            <button @click.stop="" class="copy_16 side-card side-element">Hilfe</button>
          </a>
        </div>
        <div class="copy_16 logout-button">
          <button class="logout-btn button-ivv white"
                  :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                  @click="logout">Abmelden</button>
        </div>
      </div>
    </b-sidebar>
    <button style="display: none">Hide</button>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import mandant from '@/mandant';
import Einstellung from './Einstellung.vue';

export default {
  name: 'Mehr',
  mixins: [mandant],
  data() {
    return {
      logoutAbfrage: false,
      scrollSonderfall: false,
    };
  },
  props: ['mobil'],
  components: {
    Einstellung,
  },
  computed: {
    ...mapState(['zugriffsStatus', 'mehrAktiv']),
    faqLink() {
      return this.$store.state.weiterleitungen.faq;
    },
  },
  methods: {
    ...mapMutations(['resetState']),
    async logout() {
      if (!this.logoutAbfrage) {
        this.$store.commit('resetState');
        const logoutOptions = { redirectUri: window.location.origin };
        await this.$keycloak.logout(logoutOptions);
      }
    },
    aktiviereScrollBar() {
      this.setMehr(false);
      if (!this.scrollSonderfall) {
        document.getElementById('app').style.overflow = 'auto';
      }
    },
    behandleHochScroll() {
      document.getElementById('app').style.overflow = 'hidden';
      if (!this.$store.state.mehrAktiv) {
        document.getElementById('app').scrollTo(0, 0);
      } else {
        document.getElementById('app').style.overflow = 'auto';
      }
    },
    deaktiviereScrollBar() {
      document.getElementById('app').style.overflow = 'hidden';
      this.setMehr(true);
    },
    resetKundenportal() {
      this.aktiviereScrollBar();
      this.$root.$refs.app.setZurueckFeld(false);
      this.$store.commit('resetState');
    },
    setMehr(value) {
      this.$store.state.mehrAktiv = value;
    },
    toggleMehr() {
      this.$refs.kopf.click();
    },
    zumVertreter() {
      if (this.$router.currentRoute.path !== '/vertreter') {
        this.$router.push('/vertreter');
      }
      if (this.$store.state.mehrAktiv) {
        this.toggleMehr();
      }
    },
    zumProfil() {
      if (this.$router.currentRoute.path !== '/profil') {
        this.$router.push('/profil');
      }
      if (this.$store.state.mehrAktiv) {
        this.toggleMehr();
      }
    },
  },

  created() {
    this.$root.$refs.mehr = this;
  },
};
</script>

<style>

.fa-times {
  vertical-align: text-top;
}

.side-icon {
  margin: 4px 170px 0;
  position: fixed;
  left: auto;
  right: 0;
  font-size: 19px;
}

.side-card {
  background-color: #FFFFFF;
  border: 0;
  border-bottom: 2px solid;
  border-color: #EDF0F3;
  color: #1B1C1A;
  margin-bottom: 0px;
  padding: 12px 0 14px 17px;
  text-align: left;
  width: 100%;
}

.side-card:disabled {
  color: #808485;
}

.side-x {
  margin: 4px 20px 0;
  position: fixed;
  left: auto;
  right: 0;
  height: 25px;
  border: none;
}

.side-x svg {
  vertical-align: 0;
}

.side-ueberschrift {
  color: #1B1C1A;
  background-color: #EDF0F3;
  padding: 9px 0px 9px 15px;
  margin-bottom: 0px;
}

/*.side-card:hover {*/
/*  border: 2px solid;*/
/*  border-color: #0558F0;*/
/*}*/

.logout-button {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.logout-btn {
  border: none;
  width: 90%;
  margin: 0 auto;
}

.zurueck-btn {
  border: none;
}

.zurueck-icon {
  height: 20px;
  margin-bottom: 2px;
}

.b-sidebar-vgh .b-sidebar-backdrop {
  background-color: #0446BE !important;
}

.b-sidebar-oesa .b-sidebar-backdrop {
  background-color: #78bfa4 !important;
}

.b-sidebar-oevo .b-sidebar-backdrop {
  background-color: #004494 !important;
}

.b-sidebar-backdrop {
  opacity: 0.3;
  height: 100vh;
  margin-top: 80px;
}

#mehr{
  width: 400px;
  margin-top: 80px;
}

.faqLink:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  #mehr {
    height: calc(100vh - 140px);
    margin-top: 75px;
    width: 345px;
  }
  .b-sidebar-backdrop {
    height: calc(100vh - 145px);
    margin-top: 75px;
  }

  .side-x {
    margin: 4px 20px 0;
    padding: 0;
  }
  .side-icon {
    color: #1B1C1A;
    margin: 4px 20px 0;
  }
}
</style>

<style scoped>

*:focus {
  outline:none;
}

.side-content{
  background-color: #FFFFFF;
  text-align: left;
  padding-bottom: 30px;
}

/* Verhindert, dass der X Button einen Rand hat*/
#kopf {
  padding: 30px 20px 26px;
  margin-bottom: 0;
  color: #2c3e50;
}

.b-sidebar-backdrop{
  background-color: #0558F0!important;
  opacity: 0.3!important;
}

.nav-link {
  padding: 0;
}

@media (hover: none), (pointer: coarse) {
  #kopf {
    padding: 12px 0 0 0;
  }
}

</style>
