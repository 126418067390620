<template>
  <div class="schaden-card collapsible">
    <h3>
      <button class="btn btn-collapse" type="button"
              v-b-toggle="'schaden-content-' + index"
              :ref="'button' + index">
        {{schaden.sparte}}
        <div v-if="!visible" class="copy_12 datum">
          Datum: {{formatDate(schaden.datum)}}
        </div>
        <div v-if="!visible" class="copy_12 schaden-nummer">
          Schadennummer: {{schaden.nummer}}
        </div>
        <span class="icon"
              :class="{'vgh-blau-color': mandant === 1,
                              'oesa-gruen-color': mandant === 3,
                              'oevo-blau-color': mandant === 5}">
                      <em class="fa-solid fa-plus"></em>
                      <em class="fa-solid fa-minus"></em>
                    </span>
      </button>
    </h3>
    <b-collapse :id="'schaden-content-' + index" v-model="visible" class="schaden-content copy_12">
      <div class="copy_bold_16">Datum:</div>
      {{formatDate(schaden.datum)}}<br><br>
      <div class="copy_bold_16">Schadennummer:</div>
      {{schaden.nummer}}<br><br>
      <div class="copy_bold_16">Vertragsnummer:</div>
      {{schaden.vsnr}}<br><br>
      <div class="copy_bold_16">Status:</div>
      {{formatStatus(schaden.status)}}
    </b-collapse>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Schaden',
  mixins: [mandant],
  props: {
    schaden: Object,
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    formatStatus(status) {
      let result = status;
      if (!result) {
        result = '-';
      }
      result = result.toLowerCase();
      if (result === 'offen') {
        result = 'In Prüfung, Bearbeitung ausstehend';
      }
      return result;
    },
  },
};
</script>

<style scoped>

.schaden-card {
  border-top: 2px solid #cbcfd3;
}

h3 {
  margin: 8px 0;
}

.schaden-nummer {
  position: absolute;
  right: 2.5em;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 576px) {
  .schaden-nummer {
    position: static;
    right: 0;
    top: auto;
    transform: none;
  }
}

.btn-collapse {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  box-shadow: none;
  color: var(--text-color);
  border-radius: 0;
  text-align: left;
  position: relative;
  width: 100%;
}

.btn-collapse:disabled {
  opacity: unset;
}

.btn-collapse .fa-plus, .btn-collapse .fa-minus {
  display: none;
}

.btn-collapse .icon {
  font-size: 15px;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
}

.btn-collapse.collapsed .fa-plus {
  display: inline-block;
}

.btn-collapse.not-collapsed .fa-minus {
  display: inline-block;
}

.schaden-content {
  margin-top: 1em;
  margin-bottom: 1em;
}

</style>
