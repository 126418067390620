<template>
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="verlassen"
      @zustimmung="zurueck"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @abbruch="schliesseAbfrage"
      @ablehnung="schliesseAbfrage"
      @zustimmung="behandleSeiteVerlassen">
    </sicherheitsabfrage>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <div class="row g-3">
        <div class="col-xl-8">
          <h1 class="abstand-unten-30">Dokumente anfordern</h1>
          <p class="copy_16 abstand-unten-20">
            Welche Dokumente benötigen Sie?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <input v-model="versicherungsschein"
                 id="versicherungsschein-checkbox"
                 :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                 type="checkbox">
          <label for="versicherungsschein-checkbox" class="copy_bold_16">
            Versicherungsschein
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <input v-model="beitragsbescheinigung"
                 id="beitragsbescheinigung-checkbox"
                 :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                 type="checkbox">
          <label for="beitragsbescheinigung-checkbox" class="copy_bold_16">
            Beitragsbescheinigung für alle Verträge
          </label>
          <div v-if="beitragsbescheinigung">
            <div v-for="jahr in getVerfuegbareJahre()" v-bind:key="jahr" style="margin-left: 25px">
              <input v-bind:id="`checkbox-${jahr}`"
                     :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                     type="checkbox" @change="jahreGeaendert">
              <label v-bind:for="`checkbox-${jahr}`" class="copy_bold_16">
                {{ jahr }}
              </label>
            </div>
            <div style="margin-left: 25px">
              <input id="checkbox-jaehrlich"
                     :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                     type="checkbox" @change="jahreGeaendert">
              <label for="checkbox-jaehrlich" class="copy_bold_16">
                Jährlich automatisch erhalten
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <input v-model="rechnung"
                 id="rechnung-checkbox"
                 :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                 type="checkbox">
          <label for="rechnung-checkbox" class="copy_bold_16">
            Beitragsrechnung / Lastschriftbenachrichtigung
          </label>
          <div v-if="rechnung">
            <div class="row">
              <div class="col-12">
              <textarea
                class="eingabe copy_16"
                :class="{'fehler': textFehlerRechnung,
                        'fehler-oesa': textFehlerRechnung && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': textFehlerRechnung && mandant === 5}"
                v-model="textEingabeRechnung"
                maxlength="250"
                placeholder="Können Sie die benötigte Rechnung konkreter beschreiben?*"
                id="text-rechnung"
              ></textarea>
                <label class="zaehler-label copy_12">
                  {{ textEingabeRechnung.length }} / 250 Zeichen
                </label>
                <label
                  v-if="!!textFehlerRechnung"
                  class="fehler-text copy_12">
                  {{ textFehlerRechnung }}
                </label>
              </div>
              <div class="col-12">
                <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10 abstand-unten-20">
          <input v-model="sonstigeDokumente"
                 id="sonstigeDokumente-checkbox"
                 :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                 type="checkbox">
          <label for="sonstigeDokumente-checkbox" class="copy_bold_16">
            Sonstige Dokumente
          </label>
          <div v-if="sonstigeDokumente">
            <div class="row">
              <div class="col-12">
              <textarea
                class="eingabe copy_16"
                :class="{'fehler': textFehlerSonstige,
                        'fehler-oesa': textFehlerSonstige && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': textFehlerSonstige && mandant === 5}"
                v-model="textEingabeSonstige"
                maxlength="250"
                placeholder="Können Sie das benötigte Dokument konkreter beschreiben?*"
                id="text-sonstige"
              ></textarea>
                <label class="zaehler-label copy_12">
                  {{ textEingabeSonstige.length }} / 250 Zeichen
                </label>
                <label
                  v-if="!!textFehlerSonstige"
                  class="fehler-text copy_12">
                  {{ textFehlerSonstige }}
                </label>
              </div>
              <div class="col-12">
                <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 abstand-unten-15">
        <div class="col-12">
          <input type="button"
                 id="senden-btn"
                 class="button-ivv white"
                 :class="{'button-vgh': mandant === 1,
                    'button-oesa': mandant === 3,
                    'button-oevo': mandant === 5}"
                 value="Senden"
                 :disabled="sendenButtonDisabled"
                 @click="sendeDokument"
          >
          <input type="button"
                 id="verwerfen-btn"
                 class="button-ivv"
                 value="Verwerfen"
                 @click="showAbfrage = true"
          >
          <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import isoUtil from '@/isoUtil';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Vue from 'vue';

export default {
  name: 'DokumenteAnfordern',
  mixins: [isoUtil, sicherheitsabfrage, bestaetigungUtil, mandant],
  props: ['vertreter', 'vsnr', 'laufendenr', 'produktkey'],
  components: {
    BackendFehler,
    Sicherheitsabfrage,
  },
  computed: {
    eingaben() {
      const {
        versicherungsschein,
        beitragsbescheinigung,
        jahre,
        jaehrlich,
        rechnung,
        textEingabeRechnung,
        sonstigeDokumente,
        textEingabeSonstige,
      } = this;

      return {
        versicherungsschein,
        beitragsbescheinigung,
        jahre,
        jaehrlich,
        rechnung,
        textEingabeRechnung,
        sonstigeDokumente,
        textEingabeSonstige,
      };
    },
  },
  data() {
    return {
      sendenButtonDisabled: true,
      vertreternummer: '',
      hauptvertreternummer: '',

      apiFehler: false,
      backendFehler: false,

      showAbfrage: false,

      versicherungsschein: false,

      beitragsbescheinigung: false,
      jahre: [],
      jaehrlich: false,

      rechnung: false,
      textEingabeRechnung: '',
      textFehlerRechnung: false,

      sonstigeDokumente: false,
      textEingabeSonstige: '',
      textFehlerSonstige: false,
    };
  },
  methods: {
    getVerfuegbareJahre() {
      const jahre = [];
      const jahr = new Date().getFullYear() - 1;

      for (let i = 0; i < 5; i += 1) {
        jahre.push(jahr - i);
      }

      return jahre;
    },
    async sendeDokument() {
      try {
        this.backendFehler = false;
        if (this.eingabenValide()) {
          this.sendenButtonDisabled = true;

          const {
            vertreternummer,
            hauptvertreternummer,
            vsnr,
            laufendenr,
            produktkey,
            versicherungsschein,
            beitragsbescheinigung,
            jahre,
            jaehrlich,
            rechnung,
            textEingabeRechnung,
            sonstigeDokumente,
            textEingabeSonstige,
          } = this;

          const payload = {
            vertreternummer,
            hauptvertreternummer,
            vsnr,
            laufendenr,
            produktkey,
            versicherungsschein,
            beitragsbescheinigungJahre: beitragsbescheinigung ? jahre : null,
            beitragsbescheinigungJaehrlich: beitragsbescheinigung ? jaehrlich : null,
            rechnung: rechnung ? textEingabeRechnung : null,
            sonstigeDokumente: sonstigeDokumente ? textEingabeSonstige : null,
          };

          await api.sendeDokumentAnforderung(
            payload,
            Vue.$keycloak.token,
          );
          await this.$store.dispatch('ladePostfachstatus');
          this.verlasseSeite = true;
          await this.$router.push({
            path: `/vertragsdetails/${this.vsnr}/${this.laufendenr}/${this.produktkey}`,
            name: 'Vertragsdetails',
            params: {
              bestaetigung: 'dokumente-anfordern',
            },
          });
        }
      } catch (e) {
        console.log(e);
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
      }
    },
    jahreGeaendert() {
      this.jahre = [];
      this.getVerfuegbareJahre().forEach((jahr) => {
        const { checked } = document.getElementById(`checkbox-${jahr}`);
        if (checked) {
          this.jahre.push(jahr);
        }
      });
      this.jaehrlich = document.getElementById('checkbox-jaehrlich').checked;
    },
    zurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    eingegeben() {
      return this.jahre.length > 0
        || this.jaehrlich
        || this.textEingabeRechnung
        || this.textEingabeSonstige;
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    eingabenValide() {
      this.validateTexte(true);
      const {
        versicherungsschein,
        beitragsbescheinigung,
        jahre,
        jaehrlich,
        rechnung,
        textEingabeRechnung,
        textFehlerRechnung,
        sonstigeDokumente,
        textEingabeSonstige,
        textFehlerSonstige,
      } = this;

      if (!versicherungsschein
        && !beitragsbescheinigung
        && !rechnung
        && !sonstigeDokumente) {
        // nichts eingegeben
        return false;
      }

      if (beitragsbescheinigung && jahre.length === 0 && !jaehrlich) {
        return false;
      }

      if (rechnung && (!textEingabeRechnung || textFehlerRechnung)) {
        return false;
      }

      return !(sonstigeDokumente && (!textEingabeSonstige || textFehlerSonstige));
    },
    validateTexte(leerCheck) {
      this.textFehlerRechnung = '';
      this.textFehlerSonstige = '';

      if (leerCheck) {
        if (this.rechnung && !this.textEingabeRechnung.trim()) {
          this.textFehlerRechnung = 'Bitte beschreiben Sie die benötigten Dokumente.';
        }
        if (this.sonstigeDokumente && !this.textEingabeSonstige.trim()) {
          this.textFehlerSonstige = 'Bitte beschreiben Sie die benötigten Dokumente.';
        }
      }

      if (this.rechnung && !this.checkISO(this.textEingabeRechnung)) {
        this.textFehlerRechnung = 'Bitte achten Sie auf Sonderzeichen.';
      }
      if (this.sonstigeDokumente && !this.checkISO(this.textEingabeSonstige)) {
        this.textFehlerSonstige = 'Bitte achten Sie auf Sonderzeichen.';
      }
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  beforeMount() {
    this.setZurueckFeld();
  },
  async created() {
    this.vertrag = await api.getEinzelVertrag(
      this.vsnr,
      this.laufendenr,
      this.produktkey,
      Vue.$keycloak.token,
    )
      .then((response) => response.data)
      .then((vertrag) => vertrag)
      .catch((error) => {
        console.log(error);
      });
    this.vertreternummer = this.vertrag.bestandsvertreter.vertreternummer;
    this.hauptvertreternummer = this.vertrag.bestandsvertreter.hauptvertreternummer;
  },
  watch: {
    eingaben() {
      this.validateTexte(false);
      this.sendenButtonDisabled = !(this.versicherungsschein
        || (this.beitragsbescheinigung && (this.jaehrlich || this.jahre.length > 0))
        || this.rechnung
        || this.sonstigeDokumente);
    },
  },
};
</script>

<style scoped>
#text-rechnung, #text-sonstige {
  height: 100px;
  resize: none;
}

#senden-btn {
  margin-right: 15px;
}

#senden-btn:disabled {
  background: #0558F0;
  opacity: 0.5;
}

#pflicht-label {
  color: #6B7171;
  margin: 19px 0 30px 0;
}

.zaehler-label {
  display: flex;
  justify-content: flex-end;
  color: #6B7171;
}

.eingabe {
  padding-left: 15px;
}

textarea.eingabe {
  padding-top: 9px;
}

.fehler:focus {
  outline: none !important;
}
</style>
