<template>
  <div>
    <h2 class="abstand-unten-30">Zusammenfassung Ihrer Änderung</h2>
    <label class="abstand-unten-30 copy_16" id="bitte">
      Bitte überprüfen Sie Ihre neue eingegebene Adresse
    </label>
    <div class="card abstand-unten-30 col-md-9 col-12">
      <div class="card-body">
        <p class="copy_bold_16 ">Meine neue Adresse</p>
        <p class="copy_16 abstand-unten-0">{{str}} {{nr}}</p>
        <p class="copy_16 abstand-unten-0">{{plz}} {{ort}}</p>
        <p class="copy_16 abstand-unten-0">{{teil}}</p>
        <p class="copy_16 abstand-unten-0">{{vermerk}}</p>
      </div>
    </div>
    <input @click="absenden"
           type="button"
           class="button-ivv white"
           :disabled="sendenButtonDisabled"
           :class="{'button-vgh': mandant === 1,
           'button-oesa': mandant === 3,
           'button-oevo': mandant === 5}"
           value="Absenden">
    <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
  </div>
</template>

<script>
import api from '@/backend-api';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Vue from 'vue';

export default {
  name: 'AdresseZusammenfassung',
  mixins: [mandant],
  props: ['str', 'nr', 'plz', 'ort', 'land', 'vermerk', 'teil'],
  components: {
    BackendFehler,
  },
  data() {
    return {
      apiFehler: false,
      backendFehler: false,
      sendenButtonDisabled: false,
      anschrift: {
        strasse: this.str,
        hausnummer: this.nr,
        postleitzahl: this.plz,
        ort: this.ort,
        land: this.land,
        ortsteil: this.teil,
        zustellvermerk: this.vermerk,
      },
    };
  },

  methods: {
    async absenden() {
      try {
        this.sendenButtonDisabled = true;
        this.backendFehler = false;
        await api.setPartnerAnschrift(this.anschrift, Vue.$keycloak.token);
        await this.$store.dispatch('ladePartner');
        this.$emit('fertig');
      } catch (e) {
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
#bitte {
  color: #6B7171;
  margin-bottom: 30px;
}

.card {
  width: 100%;
  height: auto;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
}

.card-body {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 576px) {
  #bitte {
    margin-bottom: 20px;
  }
}

@media (max-width: 380px) {
  .card {
    width: auto;
  }
}
</style>
