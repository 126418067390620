import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Nachricht from '@/views/Nachricht.vue';
import DokumenteAnfordern from '@/views/DokumenteAnfordern.vue';
import DokumentEinreichen from '@/views/DokumentEinreichen.vue';
import NectWartungsfenster from '@/views/NectWartungsfenster.vue';
import PasswortAendern from '@/change-personal-data/PasswortAendern.vue';
import NummerAendern from '@/change-personal-data/NummerAendern.vue';
import SchadenMelden from '@/schaden/SchadenMelden.vue';
import SchadenUebersicht from '@/schaden/SchadenUebersicht.vue';
import BankverbindungAendern from '../change-personal-data/BankverbindungAendern.vue';
import Vertreterauswahl from '../components/Vertreterauswahl.vue';
import Vertrag from '../views/Vertrag.vue';
import Vertragsdetails from '../views/Vertragsdetails.vue';
import Schaden from '../views/Schaden.vue';
import SchadenForm from '../schaden/SchadenForm.vue';
import Postfach from '../views/Postfach.vue';
import Profil from '../views/Profil.vue';
import Services from '../views/Services.vue';
import Beratungstermin from '../views/Beratungstermin.vue';
import NameAendern from '../change-personal-data/NameAendern.vue';
import AdresseAendern from '../change-personal-data/AdresseAendern.vue';
import EmailAendern from '../change-personal-data/EmailAendern.vue';
import Vertreter from '../views/Vertreter.vue';
import Home from '../views/Home.vue';
import VertreterAendern from '../change-personal-data/VertreterAendern.vue';
import Nachrichtenverlauf from '../views/Nachrichtenverlauf.vue';
import NectStart from '../views/NectStart.vue';
import Nect from '../views/Nect.vue';
import NectValidierung from '../views/NectValidierung.vue';
import NectValidierungMobile from '../views/NectValidierungMobile.vue';
import Zusammenfassung from '../views/Zusammenfassung.vue';
import PortalzugangLoeschen from '../change-personal-data/PortalzugangLoeschen.vue';
import Servicekarte from '../views/Servicekarte.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      nect: false,
    },
    props: true,
  },
  {
    path: '/name',
    name: 'name',
    component: NameAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
  },
  {
    path: '/vertreterauswahl',
    name: 'Vertreterauswahl',
    component: Vertreterauswahl,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
  },
  {
    path: '/vertreter/:vertreternummer',
    name: 'ServicekarteVertreter',
    component: Servicekarte,
    meta: {
      requiresAuth: false,
    },
    props: true,
  },
  {
    path: '/vertreter',
    name: 'Vertreter',
    component: Vertreter,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/beratungstermin',
    name: 'Beratungstermin',
    component: Beratungstermin,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/vertrag/:vsnr',
    name: 'Vertrag',
    component: Vertrag,
    props: true,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
  },
  {
    path: '/vertrag',
    name: 'Vertrag',
    component: Vertrag,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
  },
  {
    path: '/vertragsdetails/:vsnr/:laufendenr/:produktkey',
    name: 'Vertragsdetails',
    component: Vertragsdetails,
    props: true,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
  },
  {
    path: '/vertrag/servicekarte/:vsnr/:laufendenr/:produktkey',
    name: 'ServicekarteKfz',
    component: Servicekarte,
    meta: {
      requiresAuth: false,
    },
    props: true,
  },
  {
    path: '/dokument-einreichen/:vsnr/:laufendenr/:produktkey',
    name: 'DokumentEinreichen',
    component: DokumentEinreichen,
    props: true,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
  },
  {
    path: '/dokumente-anfordern/:vsnr/:laufendenr/:produktkey',
    name: 'DokumenteAnfordern',
    component: DokumenteAnfordern,
    props: true,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
  },
  {
    path: '/schaden',
    name: 'Schaden',
    component: Schaden,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
    props: true,
  },
  {
    path: '/schaden/melden/:vsnr',
    name: 'Schaden-melden',
    component: SchadenMelden,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
    props: true,
  },
  {
    path: '/schaden/melden',
    name: 'Schaden-melden',
    component: SchadenMelden,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
    props: true,
  },
  {
    path: '/schaden/uebersicht',
    name: 'Schaden-uebersicht',
    component: SchadenUebersicht,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
    props: true,
  },
  {
    path: '/schaden/:vsnr',
    name: 'Schaden',
    component: Schaden,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
    props: true,
  },
  {
    path: '/schaden-melden/:vsnr/:laufendenr/:produktkey',
    name: 'Schaden-melden',
    component: SchadenForm,
    props: true,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
      requiresRolle: ['VERSICHERUNGSNEHMER'],
    },
  },
  {
    path: '/postfach',
    name: 'Postfach',
    component: Postfach,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/konversation/:id',
    name: 'Nachrichtenverlauf',
    component: Nachrichtenverlauf,
    props: true,
    meta: {
      requiresAuth: true,

    },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/nachricht',
    name: 'Nachricht',
    component: Nachricht,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/adresse-aendern',
    name: 'AdresseAendern',
    component: AdresseAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/passwort-aendern',
    name: 'PasswortAendern',
    component: PasswortAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
    },
    props: true,
  },
  {
    path: '/nummer-aendern',
    name: 'NummerAendern',
    component: NummerAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
    },
    props: true,
  },
  {
    path: '/email-aendern',
    name: 'EmailAendern',
    component: EmailAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
    },
  },
  {
    path: '/name-aendern',
    name: 'NameAendern',
    component: NameAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/bankverbindung-aendern',
    name: 'BankverbindungAendern',
    component: BankverbindungAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/vertreter-aendern',
    name: 'VertreterAendern',
    component: VertreterAendern,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'eigeneOrga',
    },
    props: true,
  },
  {
    path: '/portalzugang-loeschen',
    name: 'PortalzugangLoeschen',
    component: PortalzugangLoeschen,
    meta: {
      requiresAuth: true,
      requiresMinStatus: 'sparkasseOderMakler',
    },
  },
  {
    path: '/nect',
    name: 'Nect',
    component: Nect,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
  {
    path: '/nect-start/:cUUID',
    name: 'NectStart',
    component: NectStart,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
  {
    path: '/nect-wartungsfenster',
    name: 'NectWartungsfenster',
    component: NectWartungsfenster,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
  {
    path: '/nect-validierung/:cUUID',
    name: 'NectValidierung',
    component: NectValidierung,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
  {
    path: '/nect-validierung-mobile/:cUUID',
    name: 'NectValidierungMobile',
    component: NectValidierungMobile,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
  {
    path: '/zusammenfassung',
    name: 'Zusammenfassung',
    component: Zusammenfassung,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
  {
    path: '/reaktivierung*',
    name: 'Reaktivierung',
    component: Home,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: {
      reaktivieren: true,
    },
  },
  {
    path: '*',
    component: Home,
    meta: {
      requiresAuth: true,
      nect: true,
    },
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollTo(0, 0);
  },
});

// Listener auf den Browser-Zurück-Button
window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

router.beforeEach((to, from, next) => {
  function navigateToNect() {
    if (Vue.$keycloak.tokenParsed.ivv_benutzerstatus === 'angelegt') {
      next(Nect);
    }
  }
  function activateViaKeycloak() {
    if (Vue.$keycloak.authenticated && (from.path === '/nect' || from.path === '/') && !to.meta.nect) {
      navigateToNect();
    }
  }
  function resetVsnrAndKonversation() {
    if (from.name) {
      store.commit('SET_VSNR', '');
      store.commit('SET_KONVERSATION', '');
    }
  }
  function setPreviousAndCurrentUrl() {
    if (to.name !== store.state.aktualisierteURL) {
      store.commit('SET_VORHERIGEURL', from.name);
      store.commit('SET_AKTUALISIERTEURL', to.name);
    }
  }
  function isNavigationAllowedBasedOnOrgaStatus() {
    // no status required -> skip the check
    if (!to.meta.requiresMinStatus) {
      return true;
    }

    if (store.state.zugriffsStatus.orgaStatus === 'eigeneOrga') {
      return true;
    }

    return to.meta.requiresMinStatus === 'sparkasseOderMakler'
      && store.state.zugriffsStatus.orgaStatus === 'sparkasseOderMakler';
  }
  function isNavigationAllowedBasedOnRolle() {
    // no rolle required -> skip the check
    if (!to.meta.requiresRolle) {
      return true;
    }
    const { rollen } = store.state.zugriffsStatus;

    // check: has partner any rolle matching the requirement?
    return !!rollen && rollen.filter((rolle) => to.meta.requiresRolle.includes(rolle)).length > 0;
  }
  function navigateNextBasedOnRouteMeta() {
    let allowNav = true;
    allowNav = allowNav && isNavigationAllowedBasedOnOrgaStatus();
    allowNav = allowNav && isNavigationAllowedBasedOnRolle();

    if (allowNav) {
      next();
    } else {
      next({ name: 'Home' });
    }
  }
  function navigate() {
    const isItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (isItABackButton && from.name === 'Home' && to.name === 'Login') {
      next(false);
    } else if (!to.matched.some((record) => record.meta.requiresAuth)
      || store.state.user.isAuthenticated) {
      store.commit('SET_FEHLER', false);
      navigateNextBasedOnRouteMeta();
    } else {
      store.commit('SET_FEHLER', false);
      next({ name: 'Login' });
    }
  }
  function startNavigation() {
    Vue.$keycloak.updateToken(70)
      .then(() => {
        resetVsnrAndKonversation();
        setPreviousAndCurrentUrl();
        navigate();
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function loginOrStartNavigation() {
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      const basePath = window.location.origin;
      const params = new URL(window.location.href).searchParams;
      const redirectUri = new URL(basePath + to.path);
      redirectUri.search = params;
      Vue.$keycloak.login({ redirectUri: redirectUri.toString(), responseMode: 'query' });
      // } else if (Vue.$keycloak.hasResourceRole('ksp-user')) {
    } else {
      // The user was authenticated, and has the app role
      startNavigation();
    }
  }

  if (to.fullPath.slice(0, 2) === '/#') {
    const path = to.fullPath.substring(2);
    next(path);
    return;
  }

  if (to.meta.requiresAuth || Vue.$keycloak.authenticated) {
    activateViaKeycloak();
    loginOrStartNavigation();
  } else {
    next();
  }
});

export default router;
