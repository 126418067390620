<template>
  <div>
    <div>
      <app-header textLine1="Schaden melden"></app-header>
      <div class="container main-content position-relative">
        <slot name="benachrichtigung"></slot>
        <div v-show="this.vertreterVertraege.length > 0" class="row g-3">
          <div class="col-10">
            <div class="ueberschrift-mit-hinweis">
              <h2 class="abstand-oben-10">
                Bitte wählen Sie zuerst den vom Schaden betroffenen Vertrag
                <div @click.self="toggleHinweis"
                  class="hilfe-icon"
                  :class="{'vgh-blau-bg': mandant === 1,
                      'oesa-gruen-bg': mandant === 3,
                      'oevo-blau-bg': mandant === 5}">?
                  <div v-if="showHinweis" class="hilfe-text">
                    <div class="row">
                      <div class="col-12">
                        <div id="close-btn" @click="schliesseHinweis">
                          <em class="fa-solid fa-xmark"></em>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                      <span class="hinweis-text-span">
                        <span class="copy_16">
                          Nicht für alle Versicherungen ist eine Schadenmeldung
                          über das Kundenportal möglich.
                          <br>
                          Wenn Ihr vom Schaden betroffener Vertrag nicht aufgeführt ist,
                          dann wenden Sie sich bitte an Ihre
                          <span v-if="mandant === 1">VGH Vertretung.</span>
                          <span v-if="mandant === 3">ÖSA Vertretung.</span>
                          <span v-if="mandant === 5">Vertretung der Öffentlichen.</span>
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <suche v-if="vsnr" class="suche"
               @selected="sucheInGruppenVertraege"
               mobile-margin="-3" tablet-margin="-4" input-width="350px">
        </suche>
        <suche v-else class="suche"
               @selected="schadenmeldungSuche"
               mobile-margin="-3" tablet-margin="-4" input-width="350px">
        </suche>
        <div v-if="suchtext.length > 0 && displayVertraege.length === 0">
          <label class="col-12 nichtsGefundenInfo">
            Ihre Suche führte zu keinem Ergebnis.</label>
        </div>
        <div class="row g-3 abstand-unten-30">
          <div id="karten" v-for="(vertrag, index) in displayVertraege"
               :key="index"
               class="copy_16 col-xl-4 abstand-unten-30"
               :class="{ 'col-auto': (windowWidth > 576) }">
            <div class="cursor-pointer h-100 w-100">
              <karte
                :feature="vertrag.gruppiert ? 'schaden/melden' : 'schaden-melden'"
                :vsnr="vertrag.vsnr"
                :laufendenr="vertrag.laufendenr"
                :produktkey="vertrag.produktkey"
                :produktname="vertrag.produktname"
                :spezifisches-merkmal="vertrag.spezifischesMerkmal"
                :jahresbeitrag="vertrag.jahresbeitrag"
                :zahlart="vertrag.zahlart"
                :button-text="buttonText"
                >
              </karte>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import AppHeader from '@/components/Header.vue';
import Karte from '@/components/Karte.vue';
import Suche from '@/components/Suche.vue';
import { mapState } from 'vuex';
import mandant from '@/mandant';
import api from '@/backend-api';
import Vue from 'vue';

export default {
  name: 'Schaden',
  mixins: [mandant],
  props: {
    vsnr: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      displayVertraege: [],
      gruppenVertraege: [],
      uebersichtVertraege: [],
      showSchadenForm: false,
      showHinweis: false,
      erfolg: false,
      globalesZurueck: true,
      zurueckAbfrage: false,
      buttonText: 'Schaden melden',
      suchtext: '',
    };
  },
  components: {
    Suche,
    AppHeader,
    Karte,
  },
  computed: {
    ...mapState(['vertreterVertraege']),
  },
  watch: {
    vsnr: {
      immediate: true,
      handler() {
        this.getDisplayVertraege();
      },
    },
  },
  created() {
    this.uebersichtVertraege = [...this.displayVertraege];
  },
  methods: {
    setZurueckFeld(zurueck) {
      this.$root.$refs.app.setZurueckFeld(zurueck);
    },
    toggleHinweis() {
      this.showHinweis = !this.showHinweis;
    },
    schliesseHinweis() {
      this.showHinweis = false;
    },
    async getDisplayVertraege() {
      this.suchtext = '';
      if (this.vsnr) {
        this.displayVertraege = [];
        this.gruppenVertraege = await api
          .getGruppenVertreterVetraege(this.vsnr, Vue.$keycloak.token)
          .then((response) => response.data)
          .then((vertrag) => vertrag)
          .catch((error) => {
            console.log(error);
          });
        this.displayVertraege = this.gruppenVertraege;
      } else {
        this.displayVertraege = this.vertreterVertraege;
      }
      this.sortiereVertraege();
    },
    sortiereVertraege() {
      this.displayVertraege = this.displayVertraege.sort((a, b) => a.produktname - b.produktname);
    },
    sucheInGruppenVertraege(suchtext) {
      this.suchtext = suchtext;
      this.displayVertraege = this.gruppenVertraege.filter(
        (v) => v.vsnr.toLowerCase().includes(suchtext.toLowerCase())
          || v.spezifischesMerkmal?.toLowerCase().includes(suchtext.toLowerCase()),
      );
    },
    schadenmeldungSuche(suchtext) {
      this.suchtext = suchtext;
      if (suchtext.trim() === '') {
        this.displayVertraege = [...this.uebersichtVertraege];
      } else {
        this.displayVertraege = this.uebersichtVertraege.filter(
          (v) => v.vsnr.toLowerCase().includes(suchtext.toLowerCase())
            || v.produktname.toLowerCase().includes(suchtext.toLowerCase())
            || v.spezifischesMerkmal?.toLowerCase().includes(suchtext.toLowerCase()),
        );
      }
    },
  },
  async mounted() {
    try {
      if (this.vsnr) {
        await this.sucheInGruppenVertraege('');
      } else {
        await this.schadenmeldungSuche('');
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      this.$emit('view-geladen');
    }
  },
  async beforeMount() {
    await this.$store.dispatch('ladeVertreterVertraege');
    this.setZurueckFeld(this.globalesZurueck);
  },
};
</script>

<style scoped>
#close-btn {
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 1;
  padding: 15px;
  margin: -15px;
}

.cursor-pointer{
  cursor: pointer;
}

.hilfe-text {
  width: 515px;
  background-color: #EDF0F3;
  color: #6B7171;
  text-align: left;
  border-radius: 2px;
  padding: 20px 15px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: -150px;
  right: 6%;
  z-index: 1;
}

.hinweis-text-span {
  padding-inline-end: 6px;
}

.hilfe-icon {
  margin-top: 12px;
  margin-left: 7px;
  line-height: 22px;
  cursor: pointer;
}

.suche {
  margin-bottom: 30px;
}

.nichtsGefundenInfo {
  background-color: #999999;
  text-align: center;
  color: white;
  margin-top: 2em;
  margin-bottom: 1em;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}

@media (max-width: 1199px) {
  .hilfe-text {
    width: 360px;
    right: unset;
    left: 0%;
    bottom: -165px;
  }
}

@media (max-width: 576px) {
  .hilfe-text {
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 730px;
    width: 80%;
    margin: auto;
    height: fit-content;
  }
}

</style>
