var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.zurueck,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"abbruch":_vm.schliesseAbfrage,"ablehnung":_vm.schliesseAbfrage,"zustimmung":_vm.behandleSeiteVerlassen}}):_vm._e(),_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.versicherungsschein),expression:"versicherungsschein"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"versicherungsschein-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.versicherungsschein)?_vm._i(_vm.versicherungsschein,null)>-1:(_vm.versicherungsschein)},on:{"change":function($event){var $$a=_vm.versicherungsschein,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.versicherungsschein=$$a.concat([$$v]))}else{$$i>-1&&(_vm.versicherungsschein=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.versicherungsschein=$$c}}}}),_c('label',{staticClass:"copy_bold_16",attrs:{"for":"versicherungsschein-checkbox"}},[_vm._v(" Versicherungsschein ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beitragsbescheinigung),expression:"beitragsbescheinigung"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"beitragsbescheinigung-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.beitragsbescheinigung)?_vm._i(_vm.beitragsbescheinigung,null)>-1:(_vm.beitragsbescheinigung)},on:{"change":function($event){var $$a=_vm.beitragsbescheinigung,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.beitragsbescheinigung=$$a.concat([$$v]))}else{$$i>-1&&(_vm.beitragsbescheinigung=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.beitragsbescheinigung=$$c}}}}),_c('label',{staticClass:"copy_bold_16",attrs:{"for":"beitragsbescheinigung-checkbox"}},[_vm._v(" Beitragsbescheinigung für alle Verträge ")]),(_vm.beitragsbescheinigung)?_c('div',[_vm._l((_vm.getVerfuegbareJahre()),function(jahr){return _c('div',{key:jahr,staticStyle:{"margin-left":"25px"}},[_c('input',{class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":("checkbox-" + jahr),"type":"checkbox"},on:{"change":_vm.jahreGeaendert}}),_c('label',{staticClass:"copy_bold_16",attrs:{"for":("checkbox-" + jahr)}},[_vm._v(" "+_vm._s(jahr)+" ")])])}),_c('div',{staticStyle:{"margin-left":"25px"}},[_c('input',{class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"checkbox-jaehrlich","type":"checkbox"},on:{"change":_vm.jahreGeaendert}}),_c('label',{staticClass:"copy_bold_16",attrs:{"for":"checkbox-jaehrlich"}},[_vm._v(" Jährlich automatisch erhalten ")])])],2):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rechnung),expression:"rechnung"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"rechnung-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.rechnung)?_vm._i(_vm.rechnung,null)>-1:(_vm.rechnung)},on:{"change":function($event){var $$a=_vm.rechnung,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.rechnung=$$a.concat([$$v]))}else{$$i>-1&&(_vm.rechnung=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.rechnung=$$c}}}}),_c('label',{staticClass:"copy_bold_16",attrs:{"for":"rechnung-checkbox"}},[_vm._v(" Beitragsrechnung / Lastschriftbenachrichtigung ")]),(_vm.rechnung)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textEingabeRechnung),expression:"textEingabeRechnung"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.textFehlerRechnung,
                      'fehler-oesa': _vm.textFehlerRechnung && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.textFehlerRechnung && _vm.mandant === 5},attrs:{"maxlength":"250","placeholder":"Können Sie die benötigte Rechnung konkreter beschreiben?*","id":"text-rechnung"},domProps:{"value":(_vm.textEingabeRechnung)},on:{"input":function($event){if($event.target.composing){ return; }_vm.textEingabeRechnung=$event.target.value}}}),_c('label',{staticClass:"zaehler-label copy_12"},[_vm._v(" "+_vm._s(_vm.textEingabeRechnung.length)+" / 250 Zeichen ")]),(!!_vm.textFehlerRechnung)?_c('label',{staticClass:"fehler-text copy_12"},[_vm._v(" "+_vm._s(_vm.textFehlerRechnung)+" ")]):_vm._e()]),_vm._m(1)])]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 abstand-unten-20"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sonstigeDokumente),expression:"sonstigeDokumente"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"sonstigeDokumente-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.sonstigeDokumente)?_vm._i(_vm.sonstigeDokumente,null)>-1:(_vm.sonstigeDokumente)},on:{"change":function($event){var $$a=_vm.sonstigeDokumente,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.sonstigeDokumente=$$a.concat([$$v]))}else{$$i>-1&&(_vm.sonstigeDokumente=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.sonstigeDokumente=$$c}}}}),_c('label',{staticClass:"copy_bold_16",attrs:{"for":"sonstigeDokumente-checkbox"}},[_vm._v(" Sonstige Dokumente ")]),(_vm.sonstigeDokumente)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textEingabeSonstige),expression:"textEingabeSonstige"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.textFehlerSonstige,
                      'fehler-oesa': _vm.textFehlerSonstige && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.textFehlerSonstige && _vm.mandant === 5},attrs:{"maxlength":"250","placeholder":"Können Sie das benötigte Dokument konkreter beschreiben?*","id":"text-sonstige"},domProps:{"value":(_vm.textEingabeSonstige)},on:{"input":function($event){if($event.target.composing){ return; }_vm.textEingabeSonstige=$event.target.value}}}),_c('label',{staticClass:"zaehler-label copy_12"},[_vm._v(" "+_vm._s(_vm.textEingabeSonstige.length)+" / 250 Zeichen ")]),(!!_vm.textFehlerSonstige)?_c('label',{staticClass:"fehler-text copy_12"},[_vm._v(" "+_vm._s(_vm.textFehlerSonstige)+" ")]):_vm._e()]),_vm._m(2)])]):_vm._e()])]),_c('div',{staticClass:"row g-3 abstand-unten-15"},[_c('div',{staticClass:"col-12"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                  'button-oesa': _vm.mandant === 3,
                  'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"senden-btn","value":"Senden","disabled":_vm.sendenButtonDisabled},on:{"click":_vm.sendeDokument}}),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}}),_c('backend-fehler',{attrs:{"api-fehler":_vm.apiFehler,"backend-fehler":_vm.backendFehler}})],1)])],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-xl-8"},[_c('h1',{staticClass:"abstand-unten-30"},[_vm._v("Dokumente anfordern")]),_c('p',{staticClass:"copy_16 abstand-unten-20"},[_vm._v(" Welche Dokumente benötigen Sie? ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")])])}]

export { render, staticRenderFns }