<template>
  <div>
    <app-header textLine1="Meine Schäden"></app-header>
    <div class="container">
      <slot name="benachrichtigung"></slot>
      <h2 class="text-links" id="aktive">Welchen Service möchten Sie nutzen?</h2>
      <div class="row">
        <div id="karten"
             v-for="service in services"
             :key="service.id"
             class="copy_16 col-xl-4"
             :class="{ 'col-auto': (windowWidth > 576) }">
          <karte-service
            :title="service.title"
            :description="service.desc"
            :button-text="service.buttonText"
            :path="service.path"
            :disabled="service.disabled">
          </karte-service>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from '@/components/Header.vue';
import mandant from '@/mandant';
import KarteService from '@/components/KarteService.vue';

export default {
  name: 'Schaden',
  mixins: [mandant],
  props: {
    vsnr: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      globalesZurueck: true,
      services: [
        {
          id: 1,
          title: 'Schaden melden',
          desc: 'Sie möchten uns einen Schaden melden?',
          buttonText: 'Zu Schaden melden',
          path: 'schaden/melden',
          disabled: false,
        },
        {
          id: 2,
          title: 'Schadenübersicht',
          desc: 'Sie möchten einen Überblick über Ihre Schäden?',
          buttonText: 'Zur Schadenübersicht',
          path: 'schaden/uebersicht',
          disabled: false,
        },
      ],
    };
  },
  components: {
    KarteService,
    AppHeader,
  },
  methods: {
    setZurueckFeld(zurueck) {
      this.$root.$refs.app.setZurueckFeld(zurueck);
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    this.setZurueckFeld(this.globalesZurueck);
  },
};
</script>

<style scoped>
#aktive {
  margin-bottom: 30px;
}

#karten{
  padding: 0 15px 30px;
}

.container {
  margin-top: 60px;
}

.text-links {
  text-align: left;
}

@media (max-width: 576px) {
  #aktive {
    margin-bottom: 20px;
  }

  #karten {
    padding-bottom: 20px;
  }

  .container {
    margin-top: 30px;
  }
}
</style>
