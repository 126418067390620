<template>
  <!-- eslint-disable max-len -->
  <div id="text-links">
    <div>
      <app-header :textLine1="headerText"></app-header>
      <div class="container main-content">
        <slot name="benachrichtigung"></slot>
        <div class="row g-3">
          <div class="col-xl-8">
            <div>
              <h2 class="ueberschrift">Meine persönlichen Daten</h2>
              <div>
                <div v-if="partner.titel">
                  <p class="copy_bold_16 abstand-unten-5">Titel</p>
                  <p class="copy_16 abstand-unten-15"> {{ partner.titel }} </p>
                </div>
                <p v-else-if="partner.vorname" class="copy_bold_16 abstand-unten-5">Vorname</p>
                <p class="copy_16 abstand-unten-15">{{ partner.vorname }}</p>
                <p class="copy_bold_16 abstand-unten-5">Nachname</p>
                <p class="copy_16 abstand-unten-15">{{ partner.namensvorsatz }} {{ partner.name }}</p>
              </div>
              <div class="aendern-btn" v-if="zugriffsStatus.orgaStatus === 'eigeneOrga'
                && !istBenutzerPartnergemeinschaft()
                && !istBenutzerFirma()">
                <button class="name-adresse-btn">
                  <pfeil-button :path="'/name-aendern'"
                                :button-text="'Persönliche Daten ändern'"
                                :disabled="zugriffsStatus.orgaStatus !== 'eigeneOrga'"
                                :class="{blau: true}"></pfeil-button>
                </button>
              </div>
              <h2 class="ueberschrift">Meine Adresse</h2>
              <div>
                <p class="copy_bold_16 abstand-unten-5">Straße & Hausnummer</p>
                <p class="copy_16 abstand-unten-15">{{ partner.anschrift.strasse }} {{ partner.anschrift.hausnummer }}</p>
                <p class="copy_bold_16 abstand-unten-5">Postleitzahl & Ort</p>
                <p class="copy_16 abstand-unten-15">{{ partner.anschrift.postleitzahl }} {{ partner.anschrift.ort }}</p>
                <div v-if="partner.anschrift.ortsteil">
                  <p class="copy_bold_16 abstand-unten-5">Ortsteil</p>
                  <p class="copy_16 abstand-unten-15">{{ partner.anschrift.ortsteil }}</p>
                </div>
                <div v-if="partner.anschrift.zustellvermerk">
                  <p class="copy_bold_16 abstand-unten-5">Zustellvermerk</p>
                  <p class="copy_16 abstand-unten-15">{{ partner.anschrift.zustellvermerk }}</p>
                </div>
                <p class="copy_bold_16 abstand-unten-5">Land</p>
                <p class="copy_16 abstand-unten-15">{{ partner.anschrift.land }}</p>
              </div>
              <div class="aendern-btn" v-if="zugriffsStatus.orgaStatus === 'eigeneOrga'
                && !istBenutzerPartnergemeinschaft()">
                <button class="name-adresse-btn">
                  <pfeil-button :path="'/adresse-aendern'"
                                :button-text="'Adresse ändern'"
                                :disabled="zugriffsStatus.orgaStatus !== 'eigeneOrga'"
                                :class="{blau: true}"></pfeil-button>
                </button>
              </div>
              <h2 class="ueberschrift">Anmeldedaten</h2>
              <div id="liste">
                <p class="copy_bold_16 abstand-unten-5">E-Mail-Adresse</p>
                <p class="copy_16 abstand-unten-15">{{ this.$keycloak.idTokenParsed.email }}</p>
                <p class="copy_bold_16 abstand-unten-5">Passwort</p>
                <p class="copy_16 abstand-unten-15">*********</p>
              </div>
              <div class="aendern-btn" v-if="zugriffsStatus.benutzerkontoStatus !== 'geloescht'">
                <div class="aendern-loeschen-btn">
                    <pfeil-button
                    :path="'/email-aendern'"
                    :button-text="'E-Mail-Adresse ändern'"
                    :disabled="zugriffsStatus.benutzerkontoStatus === 'geloescht'"
                    :class="{blau: true}">
                    </pfeil-button>
                </div>
                <div class="aendern-loeschen-btn">
                  <pfeil-button
                    :path="'/passwort-aendern'"
                    :button-text="'Passwort ändern'"
                    :disabled="zugriffsStatus.benutzerkontoStatus === 'geloescht'"
                    :class="{blau: true}"></pfeil-button>
                </div>
                <div class="aendern-loeschen-btn">
                  <pfeil-button
                    :path="'/nummer-aendern'"
                    :button-text="'Handynummer ändern'"
                    :disabled="zugriffsStatus.benutzerkontoStatus === 'geloescht'"
                    :class="{blau: true}"></pfeil-button>
                </div>
                <div class="aendern-loeschen-btn">
                  <pfeil-button
                    :path="'/portalzugang-loeschen'"
                    :button-text="'Kundenportalzugang löschen'"
                    :disabled="zugriffsStatus.benutzerkontoStatus === 'geloescht'"
                    :class="{blau: true}"></pfeil-button>
                </div>
              </div>
              <div class="aendern-btn" v-if="zugriffsStatus.benutzerkontoStatus === 'geloescht'">
                <div class="aendern-loeschen-btn">
                  <pfeil-button
                    :button-text="'Kundenportalzugang reaktivieren'"
                    :disabled="zugriffsStatus.benutzerkontoStatus !== 'geloescht'"
                    :class="{blau: true}"
                    v-on:click="benutzerReaktivieren"></pfeil-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4" id="vertreter-info">
            <vertreter-info
              ueberschrift="Kontaktieren Sie uns gerne, wenn Sie Hilfe brauchen."
              :mail-adresse="true"
              :nachricht="true"
              :vertrLink="true"
              :termin="true"
              :social-media="true"
              :vertreter="vertreter"
            >
            </vertreter-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex';
import AppHeader from '@/components/Header.vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import PfeilButton from '@/components/PfeilButton.vue';
import zugriffsstatus from '@/zugriffsstatus';
import bestaetigungUtil from '../bestaetigungUtil';

export default {
  name: 'Profil',
  mixins: [bestaetigungUtil, zugriffsstatus],
  data() {
    return {
      headerText: 'Mein Profil',
      aenderungsNachricht: '',
      profilGeaendert: false,
      showNachrichtAenderung: false,
      vertreterAnzahl: 0,
      nameAdressePfeilButton: true,
    };
  },
  components: {
    AppHeader,
    PfeilButton,
    VertreterInfo,
  },
  computed: {
    ...mapState(['partner', 'vertreter', 'zugriffsStatus']),
    ...mapGetters(['erzeugePartnerName']),
  },
  methods: {
    async benutzerReaktivieren() {
      this.$root.$refs.app.benutzerReaktivieren();
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    await this.$store.dispatch('ladePartner');
    await this.$store.dispatch('ladeVertreter');
    this.$root.$refs.app.setZurueckFeld(true);
  },
};
</script>

<style scoped>

.aendern-loeschen-btn {
  margin-bottom: 20px;
}

#text-links {
  text-align: left;
}

.aendern-btn {
  margin-top: 30px;
  margin-bottom: 60px;
}

.name-adresse-btn {
  color: #1B1C1A;
  background:none;
  border: none;
  padding-bottom: 0;
  padding-left: 0px;
  cursor: pointer;
}

.ueberschrift {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .aendern-btn {
    margin-top: 20px;
  }

  .ueberschrift {
    margin-bottom: 20px;
  }

  .col-xl-4 {
    margin-top: 45px;
  }
}

@media (max-width: 1199px) {
  #vertreter-info {
    margin-top: 60px;
  }
}

</style>
