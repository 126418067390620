<template>
<div>
  <div class="container container-kalibrierung">
    <div class="row">
      <div class="col-12 bankverbindung-titel">
        <div
          class="text-links ueberschrift-mit-hinweis copy_bold_18"
          id="aktive">Für welche Verträge soll die neue Bankverbindung gelten?
          ({{ gefilterteVertraege.length }})
          <div @click.self="toggleHinweis"
               class="hilfe-icon"
               :class="{'vgh-blau-bg': mandant === 1,
                    'oesa-gruen-bg': mandant === 3,
                    'oevo-blau-bg': mandant === 5}">?
            <div v-if="showHinweis" class="hilfe-text">
              <div class="row">
                <div class="col-12">
                  <div id="close-btn" @click="schliesseHinweis">
                    <em class="fa-solid fa-xmark"></em>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span class="hinweis-text-span">
                    Änderungen sind ausschließlich bei Verträgen möglich,
                    bei denen Sie Versicherungsnehmer sind.
                    Sollte der ausgewählte Vertrag die Zahlweise "Rechnung" aufweisen,
                    wird dieser auf Lastschrift umgestellt.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <input v-model="alleAuswaehlen"
               id="alle-auswaehlen-checkbox"
               @change="alleEinchecken"
               :class="{'input-vgh': mandant === 1,
                  'input-oesa': mandant === 3,
                  'input-oevo': mandant === 5}"
               type="checkbox">
        <label for="alle-auswaehlen-checkbox"
               class="copy_bold_16 alle-auswaehlen">
          Alle Verträge auswählen
        </label>
      </div>
    </div>
    <div class="row">
      <div id="karten" v-for="(vertrag, index) in gefilterteVertraege"
           :key="index"
           class="copy_16 col-xl-4 col-kalibrierung" :class="{ 'col-auto': (windowWidth > 576) }">
        <input
          v-model="eingecheckteVertraege"
          :id="index"
          :value="vertrag"
          class="vertragInput"
          :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
          type="checkbox"
          required>
        <label :for="index" class="bankverbindung-vertrag-checkbox">
          <div class="cursor-pointer h-100 w-100">
            <karte-vertrag-bank
              :versicherungsscheinnummer="vertrag.vsnr"
              :produktname="vertrag.produktname"
              :spezifisches-merkmal="vertrag.spezifischesMerkmal"
              :iban="vertrag.iban"
              :checked="checkboxChecked(index)"
            >
            </karte-vertrag-bank>
          </div>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="copy_bold_18 alle-auswaehlen">Soll die alte Bankverbindung gelöscht werden?</p>
      </div>
      <div>
        <input v-model="bankverbindungLoeschen"
               :value="true"
               id="bankverbindung-loeschen"
               :class="{'input-vgh': mandant === 1,
                  'input-oesa': mandant === 3,
                  'input-oevo': mandant === 5}"
               type="radio">
        <label for="bankverbindung-loeschen"
               class="copy_bold_16 alle-auswaehlen">
          Ja
        </label>
        <input v-model="bankverbindungLoeschen"
               :value="false"
               id="bankverbindung-nicht-loeschen"
               :class="{'input-vgh': mandant === 1,
                  'input-oesa': mandant === 3,
                  'input-oevo': mandant === 5}"
               type="radio">
        <label for="bankverbindung-nicht-loeschen"
               class="copy_bold_16 alle-auswaehlen">
          Nein
        </label>
      </div>
    </div>
    <div class="row">
      <button
        class="weiter button-ivv white"
        :class="{'button-vgh': mandant === 1,
        'button-oesa': mandant === 3,
        'button-oevo': mandant === 5}"
        :disabled="weiterButtonDeaktiviert"
        @click="zusammenfassung">
        Weiter
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import KarteVertragBank from '@/components/KarteMitAuswahl.vue';
import mandant from '@/mandant';

export default {
  name: 'BankverbindungVertraege',
  mixins: [mandant],
  data() {
    return {
      buttonText: 'Details & Services',
      gefilterteVertraege: [],
      eingecheckteVertraege: [],
      alleAuswaehlen: false,
      showHinweis: false,
      bankverbindungLoeschen: {},
      weiterButtonDeaktiviert: true,
    };
  },
  components: {
    KarteVertragBank,
  },

  computed: {
    ...mapState(['vertraegeIban']),
  },

  props: ['auswahl'],

  beforeMount() {
    this.filterUndSortiereVertraege();
    this.$root.$refs.app.setZurueckFeld(false);
  },

  mounted() {
    this.$emit('view-geladen');
    this.checkInVertraege();
  },

  methods: {
    checkInVertraege() {
      if (this.auswahl.length) {
        const inputs = document.getElementsByClassName('vertragInput');
        this.auswahl.forEach((element) => {
          for (let j = 0; j < this.gefilterteVertraege.length; j += 1) {
            if (this.gefilterteVertraege[j].vsnr === element.vsnr) {
              inputs[j].click();
            }
          }
        });
      }
    },
    checkboxChecked(index) {
      const input = document.getElementById(index.toString());
      return !!(input?.checked);
    },
    alleEinchecken() {
      const inputs = document.getElementsByClassName('vertragInput');
      if (this.eingecheckteVertraege.length !== this.gefilterteVertraege.length) {
        Array.from(inputs).forEach((element) => {
          if (!element.checked) {
            element.click();
          }
        });
        this.alleAuswaehlen = true;
      } else {
        Array.from(inputs).forEach((element) => {
          if (element.checked) {
            element.click();
          }
        });
        this.alleAuswaehlen = false;
      }
    },
    emitAuswahl() {
      this.$emit('vertragAuswahl', {
        eingecheckteVertraege: this.eingecheckteVertraege,
        bankverbindungLoeschen: this.bankverbindungLoeschen,
      });
    },
    filterUndSortiereVertraege() {
      // Moped-Verträge herausfiltern
      this.gefilterteVertraege = this.vertraegeIban.filter((vertrag) => !(vertrag.vsnr.startsWith('0129')
        || vertrag.vsnr.startsWith('0329')
        || vertrag.vsnr.startsWith('0529')));
      this.gefilterteVertraege = this.gefilterteVertraege
        .sort((a, b) => a.produktname - b.produktname);
    },
    zusammenfassung() {
      this.emitAuswahl();
      this.$emit('zurZusammenfassung', {
        eingecheckteVertraege: this.eingecheckteVertraege,
        bankverbindungLoeschen: this.bankverbindungLoeschen,
      });
      document.getElementById('app').scrollTo(0, 0);
    },
    toggleHinweis() {
      this.showHinweis = !this.showHinweis;
    },
    schliesseHinweis() {
      this.showHinweis = false;
    },
  },

  watch: {
    eingecheckteVertraege() {
      this.$store.state.ausgewaehlteVertraege = this.eingecheckteVertraege;
      if (this.eingecheckteVertraege.length === this.gefilterteVertraege.length
        && !this.alleAuswaehlen) {
        this.alleAuswaehlen = true;
      }
      if (this.eingecheckteVertraege.length !== this.gefilterteVertraege.length
        && this.alleAuswaehlen) {
        this.alleAuswaehlen = false;
      }
      this.weiterButtonDeaktiviert = this.eingecheckteVertraege.length === 0
        || !(this.bankverbindungLoeschen === true
          || this.bankverbindungLoeschen === false);
    },
    bankverbindungLoeschen() {
      this.weiterButtonDeaktiviert = this.eingecheckteVertraege.length === 0
        || !(this.bankverbindungLoeschen === true
          || this.bankverbindungLoeschen === false);
    },
  },
};
</script>

<style scoped>
#close-btn {
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 1;
  padding: 15px;
  margin: -15px;
}

#karten input[type="checkbox"] + label::before {
  display: none;
}

#karten label {
  display: unset;
}

.text-links {
  text-align: left;
}

#aktive {
  margin-bottom: 30px;
}

.alle-auswaehlen {
  float: left;
}

.col-kalibrierung {
  padding: 0 15px 30px;
}

.container-kalibrierung {
  margin-top: -24px;
}

.hilfe-text {
  width: 500px;
  background-color: #EDF0F3;
  color: #6B7171;
  text-align: left;
  border-radius: 2px;
  padding: 20px 15px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 22px;
  right: 0;
  z-index: 1;
}

.hilfe-icon {
  margin-top: 2px;
  margin-left: 7px;
  cursor: pointer;
}

.ueberschrift-mit-hinweis {
  line-height: 20px;
}

.weiter {
  margin-top: 20px;
  margin-left: 15px;
}

.cursor-pointer{
  cursor: pointer;
}

@media (max-width: 576px) {
  #aktive {
    margin-bottom: 20px;
    margin-top: -30px;
  }
  .bankverbindung-titel {
    max-width: 438px;
  }
  .container-kalibrierung {
    margin-top: 30px;
  }
  .col-kalibrierung {
    padding-bottom: 20px;
  }
  .hilfe-text {
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 730px;
    width: 80%;
    margin: auto;
    height: fit-content;
  }
}
</style>
