<template>
  <!-- eslint-disable max-len -->
  <div class="servicekarte">
    <div v-if="platform === 'desktop'" class="container main-content">
      <h1 class="abstand-unten-20">Servicekarte Ihrer {{ servicekarteTyp }}</h1>
      <p class="abstand-unten-40 copy_16">Scannen Sie Ihren personalisierten QR-Code mit dem Handy um die Servicekarte Ihrer {{ servicekarteTyp }} in Ihrer Wallet App zu speichern.</p>
      <qrcode-vue :value="servicekarteUrl" render-as="svg" size="300" class="abstand-unten-40"></qrcode-vue>
    </div>
  </div>
</template>

<script>
import downloadUtil from '@/downloadUtil';
import QrcodeVue from 'qrcode.vue';
import platform from '@/platform';
import Vue from 'vue';

export default {
  name: 'Servicekarte',
  props: {
    vertreternummer: {
      type: String,
      required: false,
    },
    vsnr: {
      type: String,
      required: false,
    },
    laufendenr: {
      type: String,
      required: false,
    },
    produktkey: {
      type: String,
      required: false,
    },
  },
  mixins: [downloadUtil, platform],
  components: {
    QrcodeVue,
  },
  computed: {
    servicekarteUrl() {
      if (this.vertreternummer) {
        return `${window.location.origin}/#/vertreter/${this.vertreternummer}`;
      }
      if (this.vsnr) {
        return `${window.location.origin}/#/vertrag/servicekarte/${this.vsnr}/${this.laufendenr}/${this.produktkey}`;
      }
      Vue.$log.error('Servicekarte konnte nicht geladen werden');
      return '';
    },
    servicekarteTyp() {
      if (this.vertreternummer) {
        return 'Vertretung';
      }
      if (this.vsnr) {
        return 'KFZ-Versicherung';
      }
      return '';
    },
  },
  mounted() {
    this.$emit('view-geladen');
    if (this.platform !== 'desktop') {
      if (this.vertreternummer) {
        this.downloadVertreterServicekarte(this.vertreternummer);
      } else if (this.vsnr) {
        this.downloadKfzServicekarte(this.vsnr, this.laufendenr, this.produktkey);
      }
    }
  },
  async beforeMount() {
    this.$root.$refs.app.setZurueckFeld(true);
  },
};
</script>

<style scoped>

</style>
