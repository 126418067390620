<template>
  <div>
    <label v-if="label" id="aufschrift-nachweis" class="aufschrift copy_bold_12">{{ label }}</label>
    <div @click.self="toggleHinweis"
         class="hilfe-icon"
         :style="{ right: right, height: size, width: size, 'font-size': fontSize}"
         :class="{'vgh-blau-bg': mandant === 1,
                    'oesa-gruen-bg': mandant === 3,
                    'oevo-blau-bg': mandant === 5}">?
      <div v-if="showHinweis" class="hilfe-text"
           :style="alignLeft ? { left: '-190px', width: '250px', position: 'relative'} : {}">
        <div class="row">
          <div class="col-12">
            <div id="close-btn" @click="schliesseHinweis"><em class="fa-solid fa-xmark"></em></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="hinweis-text-span"><slot></slot></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Hinweis',
  mixins: [mandant],
  data() {
    return {
      showHinweis: false,
    };
  },
  props: ['label', 'right', 'size', 'fontSize', 'alignLeft'],
  methods: {
    toggleHinweis() {
      this.showHinweis = !this.showHinweis;
    },

    schliesseHinweis() {
      this.showHinweis = false;
    },
  },
};
</script>

<style scoped>
#aufschrift-nachweis {
  margin-top: 0;
}

#close-btn {
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 1;
  padding: 15px;
  margin: -15px;
}

.aufschrift {
  color: #6B7171;
  margin-top: 22px;
  margin-bottom: 6px;
  white-space: nowrap;
}

.hilfe-icon {
  right: 9px;
  margin-top: 2px;
  cursor: pointer;
  line-height: 1.5em;
}

.hilfe-text {
  width: 500px;
  background-color: #EDF0F3;
  color: #6B7171;
  text-align: left;
  border-radius: 2px;
  padding: 20px 15px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);

  position: absolute;
  top: 25px;
  right: 0;
  z-index: 1;
}

.hinweis-text-span {
  padding-inline-end: 6px;
}

@media (max-width: 576px) {
  .hilfe-icon {
    right: 0;
  }

  .hilfe-text {
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 730px;
    width: 80%;
    margin: auto;
    height: fit-content;
  }
}
</style>
