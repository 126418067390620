var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"postfach-view"}},[_c('app-header',{attrs:{"textLine1":"Mein Postfach"}}),_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"ausloeschen","titel":_vm.titelAbfrage,"text":_vm.textAbfrage},on:{"zustimmung":_vm.softLoeschen,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),_c('div',{staticClass:"container main-content position-relative"},[_c('div',{staticClass:"row d-flex justify-content-start"},[_c('suche',{staticClass:"col-md-8 col-12",attrs:{"show-filter":true,"filter-items":_vm.vertragsarten},on:{"selected":_vm.sucheAnwenden,"filterMenuToggled":_vm.calcVertragfilterMenuShift}}),(_vm.buttonDeaktivieren)?_c('div',{staticClass:"col-md-4 col-12"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                    'button-oesa': _vm.mandant === 3,
                    'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"nachricht-btn","disabled":_vm.zugriffsStatus.orgaStatus !== 'eigeneOrga',"value":"+ Neue Nachricht"},on:{"click":_vm.zurNachricht}})]):(_vm.hatNachrichten)?_c('div',{staticClass:"col-md-4 col-12"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                    'button-oesa': _vm.mandant === 3,
                    'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"download-btn","value":"Download alle Dateien"},on:{"click":_vm.downloadAll}})]):_vm._e()],1),_c('div',{staticClass:"ansicht-filter"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ansicht),expression:"ansicht"}],attrs:{"type":"radio","id":"alle","value":"alle"},domProps:{"checked":_vm._q(_vm.ansicht,"alle")},on:{"change":[function($event){_vm.ansicht="alle"},_vm.reloadNeuigkeiten]}}),_c('label',{class:{'vgh-blau-color active': _vm.mandant === 1 && _vm.ansicht === 'alle',
                      'oesa-gruen-color active': _vm.mandant === 3 && _vm.ansicht === 'alle',
                      'oevo-blau-color active': _vm.mandant === 5 && _vm.ansicht === 'alle'},attrs:{"for":"alle"}},[_vm._v(" Alle")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ansicht),expression:"ansicht"}],attrs:{"type":"radio","id":"ungelesen","value":"ungelesen"},domProps:{"checked":_vm._q(_vm.ansicht,"ungelesen")},on:{"change":[function($event){_vm.ansicht="ungelesen"},_vm.reloadNeuigkeiten]}}),_c('label',{class:{'vgh-blau-color active': _vm.mandant === 1 && _vm.ansicht === 'ungelesen',
                      'oesa-gruen-color active': _vm.mandant === 3 && _vm.ansicht === 'ungelesen',
                      'oevo-blau-color active': _vm.mandant === 5 && _vm.ansicht === 'ungelesen'},attrs:{"for":"ungelesen"}},[_vm._v(" Ungelesen")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ansicht),expression:"ansicht"}],attrs:{"type":"radio","id":"archiv","value":"archiv"},domProps:{"checked":_vm._q(_vm.ansicht,"archiv")},on:{"change":[function($event){_vm.ansicht="archiv"},_vm.reloadNeuigkeiten]}}),_c('label',{class:{'vgh-blau-color active': _vm.mandant === 1 && _vm.ansicht === 'archiv',
                      'oesa-gruen-color active': _vm.mandant === 3 && _vm.ansicht === 'archiv',
                      'oevo-blau-color active': _vm.mandant === 5 && _vm.ansicht === 'archiv'},attrs:{"for":"archiv"}},[_vm._v(" Archiv")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 benachrichtigung"},[_vm._t("benachrichtigung")],2),_c('div',{staticClass:"col-12",attrs:{"id":"nachrichten-uebersicht"}},[(!this.isLoadingMessages && !this.neuigkeiten.length)?_c('div',[(this.postfachStatus.gesamt === 0)?_c('div',[_c('label',{staticClass:"col-12 nichtsGefundenInfo"},[_vm._v(" In Ihrem Postfach sind keine Nachrichten vorhanden. ")])]):(!!this.suchtext || this.vertragfilter.length > 0)?_c('div',[_c('label',{staticClass:"col-12 nichtsGefundenInfo"},[_vm._v(" Ihre Suche führte zu keinem Ergebnis. ")])]):(this.ansicht === 'archiv')?_c('div',[_c('label',{staticClass:"col-12 nichtsGefundenInfo"},[_vm._v(" Das Archiv enthält nur Nachrichten, die älter als ein Jahr sind. ")])]):_vm._e()]):_vm._e(),_c('swipe-list',{ref:"list",attrs:{"items":_vm.neuigkeiten,"item-key":"id","disabled":_vm.platform === 'desktop'},on:{"rightRevealed":function($event){return _vm.rightRevealed($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [_c('nachrichtenbalke',{key:index,attrs:{"neuigkeit":item},on:{"konversationAusloeschenBestaetigung":function($event){return _vm.konversationAusloeschenBestaetigung(item.konversationsId)}}})]}},{key:"right",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"swipeout-action",attrs:{"title":"Löschen"},on:{"click":function($event){return _vm.konversationAusloeschenBestaetigung(item.konversationsId)}}},[_c('span',[_vm._v("Löschen")])])]}}])}),_c('div',{ref:"swipeListEnd"}),_c('div',{style:({height: _vm.vertragfilterMenuShift + 'px'})})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }